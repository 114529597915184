import { ReactElement, useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LoginIcon from '@mui/icons-material/Login';
//import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Button,
  Dialog,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';

import { userView } from '_services_/userViewService';
import { UserAuditsDto } from '_types_/UserAuditsDto_BackEnd';
import { UserInFrontEnd_Dto } from '_types_/UserIn_Dto_BackEnd';
import { btps, roles, UserModelDto_Full } from '_types_/UserModelDto_Full';
import MDConfirmDialog from 'UI/MDConfirmDialog/MDConfirmDialog';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';
import UserViewComponent from './UserViewComponent';

const UserView = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const [data_user, setData] = useState<UserModelDto_Full>();
  const [queueResponse, setqueueResponse] = useState<UserInFrontEnd_Dto>();
  const [showQueue, setShowQueue] = useState(false);
  const [data_btpPerson, setBtpPersonData] = useState<btps[]>();

  const { trigger, formState, errors, control, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [responseMessage, setResponseMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [differentBtp, setDifferentBtp] = useState(false);
  const [userTooManyBtps, setUserTooManyBtps] = useState(false);
  const [personTooManyBtps, setPersonTooManyBtps] = useState(false);
  const [multipleRolesInApplication, setMultipleRolesInApplication] = useState<
    string | null
  >(null);

  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [availableShipTos, setAvailableShipTos] = useState<string[]>([]);

  const [hoverResendInvite, setHoverResendInvite] = useState(false);

  const [tableData, setTableData] = useState<UserAuditsDto[]>([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage] = useState(20);

  const [columns] = useState([
    {
      name: 'tableName',
      label: 'Entity',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'primaryKey',
      label: 'PK',
      options: {
        sort: false,
        display: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'comment',
      label: 'Comment',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'key',
      label: 'Attribute',
      options: {
        sort: false,
        customBodyRender: (value: string, tableMeta: any) => {
          // Get the corresponding row data using tableMeta.rowIndex
          const rowData = tableData[4];
          // Check if the key is "Failed login" and retrieve the KeyTitle
          const showTooltip = value === 'Error login';
          const keyTitle = tableMeta.rowData[4]; // Access KeyTitle from the full row data

          console.log(showTooltip);
          return showTooltip ? (
            <Tooltip title={keyTitle} arrow>
              <span>{value}</span>
            </Tooltip>
          ) : (
            <span>{value}</span>
          );
        },
      },
    },
    {
      name: 'keyTitle',
      label: 'title',
      options: {
        sort: false,
        display: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'valueOld',
      label: 'Old',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'valueNew',
      label: 'New',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'changedDate',
      label: 'Date',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value?.split('.')[0].replace('T', ' ');
        },
      },
    },
    {
      name: 'changedByUser',
      label: 'User',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'changedBySystem',
      label: 'System',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },

    // {
    //   name: 'open',
    //   label: 'Open',
    //   style: {
    //     cursor: 'pointer',
    //   },
    //   options: {
    //     sort: false,
    //     customBodyRender: () => {
    //       return (
    //         <div style={{ cursor: 'pointer' }}>
    //           <SettingsIcon />
    //         </div>
    //       );
    //     },
    //   },
    // },
  ]);

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    search: true,
    filterType: 'multiselect',
    responsive: 'vertical',
    count: count,
    selectableRows: 'none',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [20, 40, 100],
    sort: false,
    resizableColumns: false,
    onFilterChange: (
      columnChanged,
      filterList,
      type,
      changedColumnChanged,
      displayData,
    ) => {
      setCount(displayData?.length);
    },
  };

  const synchronizeClick = () => {
    setIsLoading(true);
    userView
      .sendOutUser(
        data_user?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage(
          'Action to Synchronize customer has been successfully handled (could take some minutes)',
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const resendInviteClick = (sendToMe: boolean) => {
    setIsLoading(true);
    userView
      .resendInvite(
        data_user?.person.email ?? null,
        sendToMe,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage(
          'Action to resend invite has successfully been handled',
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const adAccessCheck = () => {
    setIsLoading(true);
    userView
      .adAccessCheck(
        data_user?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then((response: AxiosResponse<string[], any>) => {
        setResponseMessage(response.data.toString());
        handleOpen();
      })
      .catch((): void => {
        setResponseMessage('Unknown error');
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const LoginCheck = () => {
    setIsLoading(true);

    userView
      .loginCheck(
        data_user?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then((response: AxiosResponse<string, any>) => {
        setResponseMessage(
          response.data === '' ? 'No login data found' : response.data,
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const ResetUser = () => {
    setIsLoading(true);
    userView
      .resetUser(
        data_user?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then((response: AxiosResponse<string, any>) => {
        setResponseMessage(
          response.data === '' ? 'Something went wrong' : response.data,
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const deleteUserClick = () => {
    setIsLoading(true);

    userView
      .deleteUser(
        data_user?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage(
          'User has been deleted, refresh the site to see the changes.',
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const handleConfirmDeleteUser = () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this user?',
    );

    if (confirmed) {
      deleteUserClick();
    }
  };

  // const reActivateUserClick = () => {
  //   setIsLoading(true);
  //   userView
  //     .reActivateUser(
  //       data_user?.userNumber.toString() ?? null,
  //       auth.user?.profile.email === undefined
  //         ? null
  //         : auth.user?.profile.email,
  //       bearerToken,
  //     )
  //     .then(() => {
  //       setResponseMessage(
  //         'Reactivated User, please refresh the site to see changes!.',
  //       );
  //       handleOpen();
  //     })
  //     .catch((error: AxiosError): void => {
  //       setResponseMessage(error.response?.data as string);
  //       handleOpen();
  //     })
  //     .finally((): void => {
  //       setIsLoading(false);
  //     });
  // };

  function analyseBtps(
    btpUser: btps[] | undefined | null,
    btpPerson: btps[] | undefined | null,
  ): boolean {
    const userBtp =
      btpUser?.find((btp) => btp.deleted === false)?.btp?.btpNumber ?? null;

    const personBtp =
      btpPerson?.find((btp) => btp.deleted === false)?.btp?.btpNumber ?? null;

    return userBtp !== null && personBtp !== null && userBtp !== personBtp;
  }

  function analyseRoles(roles: roles[] | undefined | null): string | null {
    if (!roles || roles.length < 2) {
      return null;
    }

    const applicationNumbers: Set<string> = new Set();

    for (const role of roles) {
      if (
        role.deleted === false &&
        role.applicationRole?.application?.applicationNumber
      ) {
        const applicationNumber =
          role.applicationRole.application.applicationNumber;
        if (applicationNumbers.has(applicationNumber)) {
          return applicationNumber;
        } else {
          applicationNumbers.add(applicationNumber);
        }
      }
    }

    return null;
  }

  const setShowQueueFunction = () => {
    setShowQueue(!showQueue);
  };

  const getQueue = () => {
    setIsLoading(true);
    userView
      .getQueue(data_user?.userNumber.toString() ?? null, bearerToken)
      .then((response: AxiosResponse<UserInFrontEnd_Dto>): void => {
        setqueueResponse(response.data);
      })
      .finally((): void => {
        setIsLoading(false);
      })
      .catch((error: AxiosError): void => {
        console.log(error);
        handleOpen();
      });
  };

  const getAudits = () => {
    setIsLoading(true);
    userView
      .getUserAudits(data_user?.userNumber.toString() ?? null, bearerToken)
      .then((response: AxiosResponse<UserAuditsDto[]>): void => {
        setTableData(response.data);
        setCount(response.data.length);
      })
      .catch((error: AxiosError): void => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      localStorage.removeItem('auth');

      setIsLoading(true);
      userView
        .getUser(window.location.pathname.split('/', 3)[2], bearerToken_temp)
        .then((response: AxiosResponse<UserModelDto_Full>): void => {
          setData(response.data);
        });

      userView
        .getPersonBtps(
          window.location.pathname.split('/', 3)[2],
          bearerToken_temp,
        )
        .then((response: AxiosResponse<btps[]>): void => {
          setBtpPersonData(response.data);
        })
        .finally((): void => {
          setIsLoading(false);
        });
      userView
        .getAvailableRoles(
          window.location.pathname.split('/', 3)[2],
          bearerToken_temp,
        )
        .then((response: AxiosResponse<string[]>): void => {
          setAvailableRoles(response.data);
        })
        .finally((): void => {
          setIsLoading(false);
        });
      userView
        .getAvailableShipTos(
          window.location.pathname.split('/', 3)[2],
          bearerToken_temp,
        )
        .then((response: AxiosResponse<string[]>): void => {
          setAvailableShipTos(response.data);
        })
        .finally((): void => {
          setIsLoading(false);
        });
    } else {
      setWithExpiry(
        'auth',
        '/User/' + window.location.pathname.split('/', 3)[2],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    // const different = areBtpNumbersDifferent(data_user?.btps, data_btpPerson);
    setDifferentBtp(analyseBtps(data_user?.btps, data_btpPerson));
    setMultipleRolesInApplication(analyseRoles(data_user?.roles));
    setUserTooManyBtps(
      (data_user?.btps &&
        data_user?.btps.filter((btp) => !btp.deleted).length > 1) ??
        false,
    );
    setPersonTooManyBtps(
      (data_btpPerson &&
        data_btpPerson.filter((btp) => !btp.deleted).length > 1) ??
        false,
    );
  }, [data_user, data_btpPerson]);

  useEffect(() => {
    if (
      data_user?.deleted === false &&
      data_user?.adInviteStatus === 'Accepted' &&
      data_user?.roles !== undefined &&
      data_user?.roles !== null &&
      data_user?.roles.filter((role) => role?.deleted !== false).length ===
        data_user?.roles.length // If all roles are not deleted === false
    ) {
      setInfoboxTitle('Missing role');
      setinfoboxBody(
        'Missing role might be due to the invite has expired but the user will not be deleted because the user has accepted the Microsoft policy.\nTo fix this, please make a follow up with the user and if necessary send a new invite or delete user.',
      );
      setShowTooltip(true);
    }
  }, [data_user]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [infoboxTitle, setInfoboxTitle] = useState('');
  const [infoboxBody, setinfoboxBody] = useState('');
  const [blockTooltip, setBlockTooltip] = useState(false);

  const handleMouseEnter = (event: any, title: string, body: string) => {
    if (!blockTooltip) {
      setInfoboxTitle(title);
      setinfoboxBody(body);
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    //setShowTooltip(false); // Maybe just disable this?
  };

  return bearerToken === '' ? (
    <Authentication />
  ) : (
    <div>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>
      <UserViewComponent
        control={control}
        trigger={trigger}
        errors={errors}
        formState={formState}
        setValue={setValue}
        data={data_user}
        btpPersonData={data_btpPerson}
        differentBtp={differentBtp}
        multipleRolesInApplication={multipleRolesInApplication}
        userTooManyBtps={userTooManyBtps}
        availableRoles={availableRoles}
        availableShipTos={availableShipTos}
        personTooManyBtps={personTooManyBtps}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        setBlockTooltip={setBlockTooltip}
        blockTooltip={blockTooltip}
        auth={auth}
        bearerToken={bearerToken}
      />
      <br /> <br /> <br />
      <br /> <br /> <br />
      <hr style={{ width: '60%' }} />
      <div style={{ justifyContent: 'center' }}>
        <h2
          style={{ textAlign: 'center' }}
          onMouseEnter={(event) =>
            handleMouseEnter(
              event,
              'Latest incoming request',
              'This tells some information about the latest incoming request.',
            )
          }
          onMouseLeave={handleMouseLeave}
        >
          Latest incoming request
        </h2>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ float: 'right', right: '20%' }}
          onClick={getQueue}
        >
          Load
          <RefreshIcon style={{ marginLeft: 10 }} />
        </Button>
        <div className="secondViewUser-container">
          <div>
            {queueResponse !== undefined ? (
              <>
                <p>
                  <strong>Request status: </strong> {queueResponse?.queryStatus}
                </p>
                <p>
                  <strong>Requester: </strong>{' '}
                  {queueResponse?.requestUserString.split(':')[0] +
                    '; ' +
                    queueResponse?.requestUserString.split(':')[1] +
                    ' submitted this request through ' +
                    queueResponse.requestSystem}
                </p>
                <p>
                  <strong>Date and time: </strong>{' '}
                  {queueResponse.requestDatetime?.split(' +')[0]}
                </p>
              </>
            ) : undefined}

            {queueResponse?.deleteStatus !== null &&
            queueResponse !== undefined ? (
              <>
                <p>
                  <strong>OBS: </strong> {queueResponse?.deleteStatus}
                </p>
              </>
            ) : undefined}

            {queueResponse !== undefined &&
            queueResponse?.roles !== null &&
            queueResponse?.roles.length > 0 ? (
              <div>
                <strong>Roles:</strong>
                <ul>
                  {queueResponse?.roles.map((role, index) => (
                    <li key={index}>
                      {role.deleted === false ? (
                        role.roleName
                      ) : (
                        <s>{role.roleName}</s>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : undefined}

            {queueResponse !== undefined &&
            queueResponse?.btps !== null &&
            queueResponse?.btps.length > 0 ? (
              <div>
                <strong>Btps:</strong>
                <ul>
                  {queueResponse?.btps.map((btp, index) => (
                    <li key={index}>
                      {btp.deleted === false ? (
                        `${btp.btpNumber} (${btp.btpName})`
                      ) : (
                        <s>
                          {btp.btpNumber} ({btp.btpName})
                        </s>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : undefined}

            {queueResponse !== undefined &&
            queueResponse?.invoiceAccounts !== null &&
            queueResponse?.invoiceAccounts.length > 0 ? (
              <div>
                <strong>Invoice Acccount:</strong>
                <ul>
                  {queueResponse?.invoiceAccounts.map(
                    (invoiceAccount, index) => (
                      <li key={index}>
                        {invoiceAccount.deleted === false ? (
                          `${invoiceAccount.invoiceAccountNumber} (${invoiceAccount.invoiceAccountName})`
                        ) : (
                          <s>
                            {invoiceAccount.invoiceAccountNumber} (
                            {invoiceAccount.invoiceAccountName})
                          </s>
                        )}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            ) : undefined}

            {queueResponse !== undefined &&
            queueResponse?.shipTos !== null &&
            queueResponse?.shipTos.length > 0 ? (
              <div>
                <strong>Ship Tos:</strong>
                <ul>
                  {queueResponse?.shipTos.map((shipTo, index) => (
                    <li key={index}>
                      {shipTo.deleted === false ? (
                        `${shipTo.shipToNumber} (${shipTo.shipToName})`
                      ) : (
                        <s>
                          {shipTo.shipToNumber} ({shipTo.shipToName})
                        </s>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : undefined}

            {queueResponse !== undefined &&
            queueResponse?.suppliers !== null &&
            queueResponse?.suppliers.length > 0 ? (
              <div>
                <strong>Suppliers:</strong>
                <ul>
                  {queueResponse?.suppliers.map((supplier, index) => (
                    <li key={index}>
                      {supplier.deleted === false ? (
                        `${supplier.supplierNumber} (${supplier.supplierName})`
                      ) : (
                        <s>
                          {supplier.supplierNumber} ({supplier.supplierName})
                        </s>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : undefined}
          </div>

          {queueResponse !== undefined ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              //style={{ float: 'left', right: '20%' }}
              onClick={setShowQueueFunction}
            >
              Show raw request
              <RefreshIcon style={{ marginLeft: 10 }} />
            </Button>
          ) : undefined}
          {queueResponse !== undefined && showQueue ? (
            <div>
              <br />
              <pre>
                <code className="language-json">
                  {JSON.stringify(
                    Object.fromEntries(
                      Object.entries(JSON.parse(queueResponse.message)).filter(
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        ([_, value]) => value !== null && value !== 0,
                      ),
                    ),
                    null,
                    4,
                  )}
                </code>
              </pre>
            </div>
          ) : undefined}
        </div>
      </div>
      <br /> <br /> <br />
      <br /> <br /> <br />
      <hr style={{ width: '70%' }} />
      <h2
        style={{ textAlign: 'center' }}
        onMouseEnter={(event) =>
          handleMouseEnter(
            event,
            'User changes',
            'This is a list of all the recorded changes for this user.\nSee logins: Failed and succesfull logins are being displaced in this overview.\nA login can not change value and will have a combined display of data in each row; displaying login-status ("Attribute") and a time for login ("New")\nPoint the mouse to "Error Login" to see the error message.',
          )
        }
        onMouseLeave={handleMouseLeave}
      >
        User changes{' '}
        <span
          style={{
            backgroundColor: 'gold',
            color: 'black',
            borderRadius: '4px',
            padding: '2px 6px',
            fontSize: '10px',
            fontWeight: 'bold',
          }}
        >
          NEW
        </span>
      </h2>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{ float: 'right', right: '15%' }}
        onClick={getAudits}
      >
        Load
        <RefreshIcon style={{ marginLeft: 10 }} />
      </Button>
      <div className="search" style={{ width: '80%' }}>
        <MUIDataTable
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '30px 0px',
              }}
            >
              {/* <h2 style={{ margin: '0' }}>User changes</h2> */}
            </div>
          }
          data={tableData}
          columns={columns}
          options={options}
        />
      </div>
      <div style={{ position: 'fixed', top: '20%', left: '1%' }}>
        {
          <div
            onClick={synchronizeClick}
            className="floating-container"
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Synchronize User',
                'This action will synchronize the user with the other systems and applications. \nThis can maybe solve issues where user-data does not comply with another system.' +
                  (data_user?.adInviteStatus === 'Accepted'
                    ? '\nIf the user can not login, synchronization might help.'
                    : ''),
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            <IconButton color="warning">
              <SyncIcon />
              <label className="floating-label"> Synchronize </label>
            </IconButton>
          </div>
        }
        {!data_user?.deleted && data_user?.adInviteStatus !== 'Accepted' && (
          <div
            onClick={() => resendInviteClick(false)}
            className="floating-container"
            onMouseEnter={(event) => {
              handleMouseEnter(
                event,
                'Resend invite',
                'This will resend an invitation to the user, the email will be identical to the previous invite they got. \nAny previous invitation will expire. \nIf the user has multiple applications assigned, the user will get an invitation for the application the user last got invited to',
              );
              setHoverResendInvite(true);
            }}
            onMouseLeave={() => {
              handleMouseLeave();
              setHoverResendInvite(false);
            }}
          >
            <IconButton color="info">
              <EmailIcon />
              <label className="floating-label"> Resend Invite </label>
            </IconButton>
          </div>
        )}

        {!data_user?.deleted &&
          data_user?.adInviteStatus !== 'Accepted' &&
          hoverResendInvite && (
            <div
              onClick={() => resendInviteClick(true)}
              className="floating-container"
              style={{ marginLeft: '20px' }}
              onMouseEnter={(event) => {
                handleMouseEnter(
                  event,
                  'Send email to me',
                  'This has same function as "Resend invite", but instead of sending the email to the actual user, you will send it to yourself. \nThis can be usefull if for some reason the user does not get the email, you can then forward the email to the user.',
                );
                setHoverResendInvite(true);
              }}
              onMouseLeave={() => {
                handleMouseLeave();
                setHoverResendInvite(false);
              }}
            >
              <IconButton color="info">
                <ForwardToInboxIcon />
                <label className="floating-label"> Send to me </label>
              </IconButton>
            </div>
          )}
        {/* 
        <div>
          {data_user?.deleted && (
            <div
              onClick={reActivateUserClick}
              className="floating-container"
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Reactivate User',
                  'This will re-activate the user, so that the setup before deletion will be reactiavted as well. If this is not wanted please use an other alternative to reactivate the user',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              <IconButton color="info">
                <PowerSettingsNewIcon />
                <label className="floating-label"> Reactivation </label>
              </IconButton>
            </div>
          )}
        </div> */}

        <div>
          {!data_user?.deleted && (
            <div
              onClick={adAccessCheck}
              className="floating-container"
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Access Check',
                  'Check with Microsoft to find out if this user has the expected access to the active applications.\nOBS: If there are no active applications it will still show a positive result.',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              <IconButton color="default">
                <ScreenSearchDesktopIcon />
                <label className="floating-label"> Access check </label>
              </IconButton>
            </div>
          )}
        </div>

        {!data_user?.deleted && (
          <div
            onClick={LoginCheck}
            className="floating-container"
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Check login',
                'Check if this user have had any logins at any of its applications.\nIf the user has a login, the user will be updated if necessary.\n- An update might be to set the invite to accepted or to add the application to the user.',
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            <IconButton color="default">
              <LoginIcon />
              <label className="floating-label"> Check Login </label>
            </IconButton>
          </div>
        )}

        <div style={{ marginTop: '100px' }}>
          {!data_user?.deleted &&
            auth.user?.profile.email === 'david.sorensen@bestseller.com' && (
              <div
                onClick={ResetUser}
                className="floating-container"
                onMouseEnter={(event) =>
                  handleMouseEnter(
                    event,
                    'Reset user',
                    'If the user gets an error saying something like: "User account <email> from identity provider <domain> does not exist in tenant BESTSELLER and cannot access the application...." then it might be necessary to reset the user. \nThis will reset the user and the user will get a new invite to the application.',
                  )
                }
                onMouseLeave={handleMouseLeave}
              >
                <IconButton color="error">
                  <RestartAltIcon />
                  <label className="floating-label"> Reset User </label>
                </IconButton>
              </div>
            )}

          {!data_user?.deleted && (
            <div
              onClick={handleConfirmDeleteUser}
              className="floating-container"
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Delete User',
                  'This will delete the user.\nThe user will loose all access to all applications.',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              <IconButton color="error">
                <DeleteIcon />
                <label className="floating-label"> Delete user </label>
              </IconButton>
            </div>
          )}
        </div>
      </div>
      {showTooltip && (
        <div className="infobox" style={{ whiteSpace: 'pre-line' }}>
          <h2>{infoboxTitle}</h2>
          <p dangerouslySetInnerHTML={{ __html: infoboxBody }} />
        </div>
      )}
      {/* Popup after axios call */}
      <MDConfirmDialog
        message={responseMessage}
        open={open}
        onClose={handleClose}
        disableActions={true}
      />
    </div>
  );
};

export default UserView;
