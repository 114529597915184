import { addMinutes } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setWithExpiry = (key: any, value: any) => {
  const now = new Date();
  const item = {
    path: value,
    expiry: addMinutes(now, 30),
  };

  localStorage.setItem(key, JSON.stringify(item));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getWithExpiry = (key: any) => {
  const now = new Date();
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.path;
};
