import { ReactElement, useEffect, useState } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { differenceInDays, parseISO } from 'date-fns';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { Pie, PieChart } from 'recharts';

import { frontendService } from '_services_/frontendService';
import { Governance_DatErrorClass } from '_types_/GovernanceDto';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const Governance = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage] = useState(15);

  const [data_Governance, setData_Governance] = useState<
    Governance_DatErrorClass[]
  >([]);

  const findTitle = (errorMessage: any, identifier: string) => {
    switch (errorMessage) {
      case 'User missing Role':
        return (
          <>
            Add new role to{' '}
            <a
              href="#"
              onClick={() => window.open(`/User/${identifier}`, '_blank')}
            >
              user
            </a>
          </>
        );
      case 'Admin-user missing Btp':
        return (
          <>
            Add Btp to{' '}
            <a
              href="#"
              onClick={() => window.open(`/User/${identifier}`, '_blank')}
            >
              user
            </a>
          </>
        );
      case 'Normal-user missing ShipTo':
        return (
          <>
            Add ShipTo to{' '}
            <a
              href="#"
              onClick={() => window.open(`/User/${identifier}`, '_blank')}
            >
              user
            </a>
          </>
        );
      case 'Btp without admin':
        return 'Create new Admin';
      case 'Contact missing Btp':
        return (
          <>
            Add{' '}
            <a
              href="#"
              onClick={() => window.open(`/Contact/${identifier}`, '_blank')}
            >
              contact
            </a>{' '}
            to Btp in BestOne
          </>
        );
      default:
        return 'Unknown';
    }
  };

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    search: true,
    filterType: 'multiselect',
    responsive: 'vertical',
    count: count,
    selectableRows: 'none',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [15, 20, 100],
    sort: false,
    resizableColumns: true,
    onFilterChange: (
      columnChanged,
      filterList,
      type,
      changedColumnChanged,
      displayData
    ) => {
      setCount(displayData?.length);
    },
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      setIsLoading(true);
      frontendService
        .getGovernance(bearerToken_temp)
        .then((response: AxiosResponse<Governance_DatErrorClass[]>): void => {
          setData_Governance(response.data);
          setCount(response.data.length);
          //setDataPie(marketNameCountArray);
        })
        .finally((): void => {
          setIsLoading(false);
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/Governance');
    }
  }, [auth, dispatch]);
  const [columns] = useState([
    {
      name: 'identifier',
      label: 'Identifier',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'errorDesciptionName',
      label: 'Error Description',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'marketName',
      label: 'Market',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'createdDate',
      label: 'Created Date',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value.split('T')[0];
        },
      },
    },
    {
      name: 'createdDate',
      label: 'Days of error',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return differenceInDays(new Date(), parseISO(value));
        },
      },
    },
    {
      name: 'Action',
      label: 'Take action',
      options: {
        sort: false,

        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>{findTitle(tableMeta.rowData[1], tableMeta.rowData[0])}</div>
          );
        },
      },
    },
  ]);

  interface MarketCount {
    marketName: string;
    count: number;
  }

  const [dataPie, setDataPie] = useState<MarketCount[]>([]);

  useEffect(() => {
    setDataPie(marketNameCountArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_Governance]);

  const marketNameCountArray: MarketCount[] = data_Governance.reduce(
    (acc: MarketCount[], current) => {
      const marketName = current.marketName;
      if (!marketName) {
        return acc;
      } // Skip if marketName is null

      const existingMarket = acc.find(
        (item: MarketCount) => item.marketName === marketName
      );
      if (existingMarket) {
        existingMarket.count += 1;
      } else {
        acc.push({ marketName: marketName, count: 1 });
      }
      acc.sort((a, b) => b.count - a.count);
      return acc;
    },
    []
  );

  const [isDragging, setIsDragging] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const handleDragStart = (e: any) => {
    e.dataTransfer.setData('text/plain', ''); // Necessary for drag events to work in some browsers
    setIsDragging(true);
    setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
    setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
  };

  const handleDragEnd = (e: any) => {
    const x = e.clientX - offsetX;
    const y = e.clientY - offsetY;
    e.target.style.left = x + 'px';
    e.target.style.top = y + 'px';
    setIsDragging(false);
  };

  return bearerToken === '' ? (
    <Authentication />
  ) : (
    <div>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>
      <div
        className={`movable ${isDragging ? 'dragging' : ''}`}
        draggable="true"
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        style={{ position: 'fixed', top: '60px', right: '-100px' }}
      >
        <PieChart width={800} height={800}>
          <Pie
            dataKey="count"
            startAngle={180}
            endAngle={-180}
            data={dataPie}
            cx="50%"
            cy="50%"
            outerRadius={200}
            fill="#bababa"
            label={({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
              const RADIAN = Math.PI / 180;
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  fill="#000"
                  textAnchor={x > cx ? 'start' : 'end'}
                  dominantBaseline="central"
                >
                  {dataPie[index].marketName}
                </text>
              );
            }}
          />
        </PieChart>
      </div>
      <div className="governance">
        <MUIDataTable
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '30px 0px',
              }}
            >
              <h2 style={{ margin: '0' }}>Governance Overview</h2>
            </div>
          }
          data={data_Governance}
          columns={columns}
          options={options}
        />
      </div>
      {/* <div
        className={`movable ${isDragging ? 'dragging' : ''}`}
        draggable="true"
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      >
        Drag me!
      </div> */}
    </div>
  );
};

export default Governance;
