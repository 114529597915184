import { combineReducers } from 'redux';

import FetchDataReducer from './FetchDataReducer';
import SearchForBtpReducer from './SearchForBtpReducer';
import SearchForPersonReducer from './SearchForPersonReducer';
import SearchForUserReducer from './SearchForUserReducer';

const rootReducer = combineReducers({
  SearchForUser: SearchForUserReducer,
  SearchForPerson: SearchForPersonReducer,
  SearchForBtp: SearchForBtpReducer,
  FetchData: FetchDataReducer,
});

export default rootReducer;
