import { ReactElement, useEffect, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { Button, Dialog, LinearProgress } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { basicService } from '_services_/basicService';
import { frontendService } from '_services_/frontendService';
import { FolderInfo } from '_types_/FolderInfo';
import MDConfirmDialog from 'UI/MDConfirmDialog/MDConfirmDialog';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const Email = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();

  const { control } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [folderInfo, setFolderInfo] = useState<FolderInfo[]>([]);
  const [selectedApplication, setSelectedApplication] =
    useState('Customer Portal');
  const [applications, setApplications] = useState<string[]>([]);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [selectedfilename, setSelectedFilename] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    console.log('sendTestEmail');
    setIsLoading(true);
    frontendService
      .sendTestEmail(
        selectedApplication,
        selectedfilename,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage('A test-email has been sent to your inbox!');
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const setFilenamesDropDown = async (application: string) => {
    // Find the FolderInfo object for the specified application
    const folder = folderInfo.find(
      (folder) => folder.folderName === application,
    );
    console.log(folder);
    if (folder) {
      // Return the array of filenames if found, or an empty array if fileNames is null
      setFilenames(folder.fileNames ?? []);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSelectedFilename(folder.fileNames![0]);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      //Get FolderInfo
      basicService
        .getFolderInfo()
        .then((response: AxiosResponse<FolderInfo[]>): void => {
          console.log(response.data);
          setFolderInfo(response.data);
          const folderNames = response.data
            .filter((folder) => folder.folderName !== null) // Filter out folders where folderName is null
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((folder) => folder.folderName!);
          setApplications(folderNames);

          // First load
          const folder = response.data.find(
            (folder) => folder.folderName === selectedApplication,
          );
          console.log(folder);
          if (folder) {
            console.log(folder.fileNames);
            // Return the array of filenames if found, or an empty array if fileNames is null
            setFilenames(folder.fileNames ?? []);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setSelectedFilename(folder.fileNames![0]);
          }
        })
        .finally((): void => {
          setIsLoading(false);
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/Email');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  return (
    <>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <div className="main-container">
          <>
            <Dialog open={isLoading}>
              <LinearProgress style={{ width: '200px' }} />
            </Dialog>
            <div className="inputField-root">
              <MDDropDown
                control={control}
                className="inputField"
                label="Application"
                name="application"
                error={false}
                value={selectedApplication}
                items={applications}
                onChangeSetValue={(application: string): void => {
                  setSelectedApplication(application);
                  setFilenamesDropDown(application);
                }}
              />
              <MDDropDown
                control={control}
                className="inputField"
                label="Filename"
                name="filename"
                error={false}
                value={selectedfilename}
                items={filenames}
                onChangeSetValue={(filename: string): void => {
                  setSelectedFilename(filename);
                }}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              className="sendButton"
              style={{ float: 'right' }}
              type="submit"
              onClick={async () => {
                handleSubmit();
              }}
            >
              Send test email
              <SendIcon style={{ marginLeft: 10 }} />
            </Button>
          </>
        </div>
      )}
      {/* Popup after axios call */}
      <MDConfirmDialog
        message={responseMessage}
        open={open}
        onClose={handleClose}
        disableActions={true}
      />
    </>
  );
};

export default Email;
