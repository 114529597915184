import { SearchForBtpAction } from '_types_/actions/SearchForBtpAction';

export const SET_BTPCRITERIA_FIRSTNAME = 'SET_BTPCRITERIA_FIRSTNAME';
export const SET_BTPCRITERIA_LASTNAME = 'SET_BTPCRITERIA_LASTNAME';
export const SET_BTPCRITERIA_LANGUAGE = 'SET_BTPCRITERIA_LANGUAGE';
export const SET_BTPCRITERIA_MARKET = 'SET_BTPCRITERIA_MARKET';
export const SET_BTPCRITERIA_EMAIL = 'SET_BTPCRITERIA_EMAIL';
export const SET_BTPCRITERIA_ADINVITESTATUS = 'SET_BTPCRITERIA_ADINVITESTATUS';
export const SET_BTPCRITERIA_APPLICATION = 'SET_BTPCRITERIA_APPLICATION';
export const GET_BTPCRITERIA_BTPS = 'GET_BTPCRITERIA_BTPS';
export const SET_BTPCRITERIA_BTPS = 'SET_BTPCRITERIA_BTPS';
export const SET_BTPCRITERIA_BTPNUMBER = 'SET_BTPCRITERIA_BTPNUMBER';
export const SET_BTPCRITERIA_SUPPLIERNUMBER = 'SET_BTPCRITERIA_SUPPLIERNUMBER';
export const SET_BTPCRITERIA_SHIPTONUMBER = 'SET_BTPCRITERIA_SHIPTONUMBER';
export const SET_BTP_WITHOUT_USER = 'SET_BTP_WITHOUT_USER';

export const searchForBtpActionInitialState: SearchForBtpAction = {
  type: '',
  BtpSearchCriteria: {
    market: 'All',
    application: 'None',
    btpNumber: 0,
    btpWithoutUser: false,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SearchForBtpReducer = (
  state = searchForBtpActionInitialState,
  action: SearchForBtpAction,
) => {
  switch (action.type) {
    case SET_BTPCRITERIA_MARKET: {
      let marketValue: string | null = '';
      if (action.BtpSearchCriteria.market === '') {
        marketValue = null;
      } else {
        marketValue = action.BtpSearchCriteria.market;
      }
      return {
        ...state,
        BtpSearchCriteria: {
          ...state.BtpSearchCriteria,
          market: marketValue,
        },
      };
    }

    case SET_BTPCRITERIA_APPLICATION: {
      let applicationValue: string | null = '';
      if (action.BtpSearchCriteria.application === '') {
        applicationValue = null;
      } else {
        applicationValue = action.BtpSearchCriteria.application;
      }
      return {
        ...state,
        BtpSearchCriteria: {
          ...state.BtpSearchCriteria,
          application: applicationValue,
        },
      };
    }

    case SET_BTP_WITHOUT_USER: {
      const btpWithoutUserValue = action.BtpSearchCriteria.btpWithoutUser;
      return {
        ...state,
        BtpSearchCriteria: {
          ...state.BtpSearchCriteria,
          btpWithoutUser: btpWithoutUserValue,
        },
      };
    }

    case SET_BTPCRITERIA_BTPNUMBER: {
      let btpNumberValue: number | null = 0;

      if (isNaN(action.BtpSearchCriteria.btpNumber)) {
        btpNumberValue = 0;
      } else {
        btpNumberValue = action.BtpSearchCriteria.btpNumber;
      }

      return {
        ...state,
        BtpSearchCriteria: {
          ...state.BtpSearchCriteria,
          btpNumber: btpNumberValue,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default SearchForBtpReducer;
