import { ReactElement, useState } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { CUSTOMER_NUMBER, REQUIRED_FIELD } from '_constants_/constants';
import {
  SET_BTPCRITERIA_APPLICATION,
  SET_BTPCRITERIA_BTPNUMBER,
  SET_BTPCRITERIA_MARKET,
  SET_BTP_WITHOUT_USER,
} from '_reducers_/SearchForBtpReducer';
import { FetchDataAction } from '_types_/actions/FetchDataAction';
import { SearchForBtpAction } from '_types_/actions/SearchForBtpAction';
import MDCheckBox from 'UI/MDCheckBox/MDCheckBox';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import MDTextField from 'UI/MDTextField/MDTextField';

interface PropType {
  control: any;
  errors: any;
  trigger: any;
  formState: any;
  setValue: any;
  isLoading: boolean;
}

const BtpSearchComponent = (props: PropType): ReactElement<PropType> => {
  const { control, errors, setValue, isLoading } = props;

  const [overallInvited, setOverallInvited] = useState(false);

  const handleChangeOverallInvited = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setOverallInvited(event.target.checked);
  };

  const searchForBtp = useSelector(
    (state: { SearchForBtp: SearchForBtpAction }) => state.SearchForBtp,
  );

  const fetchData = useSelector(
    (state: { FetchData: FetchDataAction }) => state.FetchData,
  );

  const dispatch = useDispatch();

  return (
    <>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>

      <div className="inputField-root">
        <MDDropDown
          control={control}
          className="inputField"
          label="Market"
          name="market"
          error={errors.market}
          value={searchForBtp.BtpSearchCriteria.market}
          items={['All', ...fetchData.FetchData.allMarkets]}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_BTPCRITERIA_MARKET,
              BtpSearchCriteria: { market: e },
            });
          }}
        />
      </div>

      <div className="inputField-root">
        <MDDropDown
          control={control}
          className="inputField"
          label="Application"
          name="application"
          error={errors.application}
          value={searchForBtp.BtpSearchCriteria.application}
          items={[
            'None',
            ...fetchData.FetchData.allApplications.filter(
              (app) => app !== 'BESTSELLER Login' && app !== 'Vendor Portal',
            ),
          ]}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_BTPCRITERIA_APPLICATION,
              BtpSearchCriteria: { application: e },
            });
          }}
        />
      </div>
      <div className="inputField-root">
        <MDTextField
          control={control}
          setValue={setValue}
          className="inputField"
          label="Business Trading Partner Number"
          name="btpNumber"
          error={errors.btpNumber}
          value={searchForBtp.BtpSearchCriteria.btpNumber}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_BTPCRITERIA_BTPNUMBER,
              BtpSearchCriteria: { btpNumber: Number(e) },
            });
          }}
          rules={{
            required: { message: REQUIRED_FIELD, value: false },
            pattern: {
              value: CUSTOMER_NUMBER,
              message: 'Invalid number',
            },
          }}
        />
      </div>
      <div>
        <MDCheckBox
          name={'Btp without invite'}
          value={overallInvited ? true : false}
          control={control}
          label={
            <div style={{ color: '#1578CF', fontWeight: 'bold' }}>
              Btp without admin (select market)
            </div>
          }
          onChangeSetValue={(event) => {
            handleChangeOverallInvited(event);
            dispatch({
              type: SET_BTP_WITHOUT_USER,
              BtpSearchCriteria: {
                btpWithoutUser: Boolean(event.target.checked),
              },
            });
          }}
        />
      </div>
    </>
  );
};

export default BtpSearchComponent;
