import { Dispatch } from 'react';

import { AxiosResponse } from 'axios';

import {
  GET_CRITERIA_APPLICATION,
  GET_CRITERIA_LANGUAGE,
  GET_CRITERIA_MARKET,
} from '_reducers_/FetchDataReducer';
import { basicService } from '_services_/basicService';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAllApplications = (
  setIsLoading: (a: boolean) => void,
  dispatch: Dispatch<any>
) => {
  setIsLoading(true);
  basicService
    .getAllApplications()
    .then((response: AxiosResponse<string[]>): void => {
      dispatch({
        type: GET_CRITERIA_APPLICATION,
        FetchData: { allApplications: response.data },
      });
    })
    .finally((): void => {
      setIsLoading(false);
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAllLanguages = (
  setIsLoading: (a: boolean) => void,
  dispatch: Dispatch<any>
) => {
  setIsLoading(true);
  basicService
    .getAllLanguages()
    .then((response: AxiosResponse<string[]>): void => {
      dispatch({
        type: GET_CRITERIA_LANGUAGE,
        FetchData: { allLanguages: response.data },
      });
    })
    .finally((): void => {
      setIsLoading(false);
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAllMarkets = (
  setIsLoading: (a: boolean) => void,
  dispatch: Dispatch<any>
) => {
  setIsLoading(true);
  basicService
    .getAllMarkets()
    .then((response: AxiosResponse<string[]>): void => {
      dispatch({
        type: GET_CRITERIA_MARKET,
        FetchData: { allMarkets: response.data },
      });
    })
    .finally((): void => {
      setIsLoading(false);
    });
};
