import React, { ReactElement, useRef } from 'react';

import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

interface PropTypes {
  name: string;
  value: any;
  control: any;
  label: any;
  onChangeSetValue: (value: any) => void;
}

const MDCheckBox = (props: PropTypes): ReactElement<PropTypes> => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Controller
        control={props.control}
        render={({ onChange }) => (
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    inputRef={inputRef}
                    checked={props.value ? props.value : false}
                    onChange={(event) => {
                      props.onChangeSetValue(event);
                      onChange(event.target.checked);
                    }}
                  />
                </div>
              }
              label={props.label}
            />
          </FormControl>
        )}
        name={props.name}
        onFocus={() => {
          inputRef.current?.focus();
        }}
        defaultValue={props.value ? props.value : false}
      />
    </>
  );
};
export default MDCheckBox;
