import { ReactElement, useEffect, useState } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { basicService } from '_services_/basicService';
import { graphService } from '_services_/graphService';
import { UserOverviewCriteriaModelDto } from '_types_/UserOverviewCriteriaModelDto';
import MDCheckBox from 'UI/MDCheckBox/MDCheckBox';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const UserOverview = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const { control } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<any[]>([]);
  const [markets, setMarkets] = useState<string[]>([]);

  const [overall, setOverall] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [invited, setInvited] = useState(false);

  const [selectedMarket, setSelectedMarket] = useState('ALL');

  const [applications, setApplication] = useState<string[]>([]);

  const [selectedApplication, setSelectedApplication] = useState('ALL');

  //const [numberOfMonth, setNumberOfMonth] = useState(0);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${month} ${date.getDate()} - ${year}`;
  };

  // const setIntervalForGraph = (graphData: any) => {
  //   const uniqueMonths = new Set();

  //   graphData.forEach((data: any) => {
  //     const date = new Date(data.date);
  //     const month = date.getMonth() + 1; // This will give you the month (0-indexed)
  //     uniqueMonths.add(month.toString() + date.getFullYear().toString());
  //   });
  //   setNumberOfMonth(uniqueMonths.size);
  // };

  const getUserOverview = async (
    market: any,
    application: any,
    status: any
  ) => {
    setIsLoading(true);

    const criteria: UserOverviewCriteriaModelDto = {
      market: market,
      application: application,
      status: status,
    };

    criteria.status = 'Invited';

    const chartDataInvited: any[] = [];

    graphService
      .getUserOverview(criteria, bearerToken)
      .then((response: AxiosResponse<Date[]>): void => {
        const countsByDate: { [date: string]: number } = {};

        response.data.forEach((item) => {
          const date: string = new Date(item).toISOString().slice(0, 10);
          countsByDate[date] = (countsByDate[date] || 0) + 1;
        });

        let countTemp = 0;

        Object.keys(countsByDate).forEach((date) => {
          countTemp += countsByDate[date];
          chartDataInvited.push({ date, count: countTemp });
        });
      })
      .finally((): void => {
        const chartDataAccepted: any[] = [];
        criteria.status = 'Accepted';
        graphService
          .getUserOverview(criteria, bearerToken)
          .then((response: AxiosResponse<Date[]>): void => {
            const countsByDate: { [date: string]: number } = {};

            response.data.forEach((item) => {
              const date: string = new Date(item).toISOString().slice(0, 10);
              countsByDate[date] = (countsByDate[date] || 0) + 1;
            });

            let countTemp = 0;

            Object.keys(countsByDate).forEach((date) => {
              countTemp += countsByDate[date];
              chartDataAccepted.push({ date, count: countTemp });
            });
          })
          .finally((): void => {
            criteria.status = 'ALL';
            graphService
              .getUserOverview(criteria, bearerToken)
              .then((response: AxiosResponse<Date[]>): void => {
                const countsByDate: { [date: string]: number } = {};
                response.data.forEach((item) => {
                  const date: string = new Date(item)
                    .toISOString()
                    .slice(0, 10);
                  countsByDate[date] = (countsByDate[date] || 0) + 1;
                });

                const chartData: any[] = [];
                let countOverall = 0;

                let indexInvited = 0;
                let indexAccepted = 0;

                let countAcceptedTemp = 0;
                let countInvitedTemp = 0;

                Object.keys(countsByDate).forEach((date) => {
                  indexInvited =
                    chartDataInvited.findIndex((item) => item.date === date) >
                    -1
                      ? chartDataInvited.findIndex((item) => item.date === date)
                      : indexInvited;
                  indexAccepted =
                    chartDataAccepted.findIndex((item) => item.date === date) >
                    -1
                      ? chartDataAccepted.findIndex(
                          (item) => item.date === date
                        )
                      : indexAccepted;

                  countInvitedTemp =
                    chartDataInvited[indexInvited] === undefined
                      ? countInvitedTemp
                      : parseInt(chartDataInvited[indexInvited].count);

                  countAcceptedTemp =
                    chartDataAccepted[indexAccepted] === undefined
                      ? countAcceptedTemp
                      : parseInt(chartDataAccepted[indexAccepted].count);

                  countOverall += countsByDate[date];

                  chartData.push({
                    date,
                    countOverall: countOverall,
                    countAccepted: countAcceptedTemp,
                    countInvited: countInvitedTemp,
                  });
                });
                setData(chartData);

                //setIntervalForGraph(chartData);
              })
              .finally((): void => {
                setIsLoading(false);
              });
          });
      });
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      setIsLoading(true);

      basicService
        .getAllMarkets()
        .then((response: AxiosResponse<string[]>): void => {
          setMarkets(response.data);
        })
        .finally((): void => {
          basicService
            .getAllApplications()
            .then((response: AxiosResponse<string[]>): void => {
              setApplication(
                response.data.filter((str) => str !== 'Vendor Portal')
              );
            });
        });

      const criteriaInvited: UserOverviewCriteriaModelDto = {
        market: 'ALL',
        application: 'ALL',
        status: 'Invited',
      };

      const chartDataInvited: any[] = [];

      graphService
        .getUserOverview(criteriaInvited, bearerToken_temp)
        .then((response: AxiosResponse<Date[]>): void => {
          const countsByDate: { [date: string]: number } = {};

          response.data.forEach((item) => {
            const date: string = new Date(item).toISOString().slice(0, 10);
            countsByDate[date] = (countsByDate[date] || 0) + 1;
          });

          let countTemp = 0;

          Object.keys(countsByDate).forEach((date) => {
            countTemp += countsByDate[date];
            chartDataInvited.push({ date, count: countTemp });
          });
        })
        .finally((): void => {
          const criteriaAccepted: UserOverviewCriteriaModelDto = {
            market: 'ALL',
            application: 'ALL',
            status: 'Accepted',
          };

          const chartDataAccepted: any[] = [];

          graphService
            .getUserOverview(criteriaAccepted, bearerToken_temp)
            .then((response: AxiosResponse<Date[]>): void => {
              const countsByDate: { [date: string]: number } = {};

              response.data.forEach((item) => {
                const date: string = new Date(item).toISOString().slice(0, 10);
                countsByDate[date] = (countsByDate[date] || 0) + 1;
              });

              let countTemp = 0;

              Object.keys(countsByDate).forEach((date) => {
                countTemp += countsByDate[date];
                chartDataAccepted.push({ date, count: countTemp });
              });
            })
            .finally((): void => {
              const criteriaAll: UserOverviewCriteriaModelDto = {
                market: 'ALL',
                application: 'ALL',
                status: 'ALL',
              };
              graphService
                .getUserOverview(criteriaAll, bearerToken_temp)
                .then((response: AxiosResponse<Date[]>): void => {
                  const countsByDate: { [date: string]: number } = {};

                  response.data.forEach((item) => {
                    const date: string = new Date(item)
                      .toISOString()
                      .slice(0, 10);
                    countsByDate[date] = (countsByDate[date] || 0) + 1;
                  });

                  const chartData: any[] = [];
                  let countOverall = 0;
                  let indexInvited = 0;
                  let indexAccepted = 0;

                  Object.keys(countsByDate).forEach((date) => {
                    indexInvited =
                      chartDataInvited.findIndex((item) => item.date === date) >
                      -1
                        ? chartDataInvited.findIndex(
                            (item) => item.date === date
                          )
                        : indexInvited;
                    indexAccepted =
                      chartDataAccepted.findIndex(
                        (item) => item.date === date
                      ) > -1
                        ? chartDataAccepted.findIndex(
                            (item) => item.date === date
                          )
                        : indexAccepted;

                    countOverall += countsByDate[date];
                    chartData.push({
                      date,
                      countOverall: countOverall,
                      countAccepted: parseInt(
                        chartDataAccepted[indexAccepted].count
                      ),
                      countInvited: parseInt(
                        chartDataInvited[indexInvited].count
                      ),
                    });
                  });
                  setData(chartData);

                  //setIntervalForGraph(chartDataOverall);
                })
                .finally((): void => {
                  setIsLoading(false);
                });
            });
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/UserOverview');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  const handleChangeOverall = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setOverall(event.target.checked);
  };
  const handleChangeAccepted = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setAccepted(event.target.checked);
  };
  const handleChangeInvited = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setInvited(event.target.checked);
  };

  return (
    <div className="search" style={{ marginTop: '10px' }}>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <>
          <Dialog open={isLoading}>
            <LinearProgress style={{ width: '200px' }} />
          </Dialog>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <div
              style={{
                alignItems: 'center',
                width: '900px',
                display: 'inline-flex',
              }}
            >
              <MDDropDown
                control={control}
                className="inputField"
                label="Market"
                name="market"
                error={false}
                value={selectedMarket}
                items={['ALL', ...markets]}
                onChangeSetValue={(market: string): void => {
                  getUserOverview(market, selectedApplication, 'ALL');
                  setSelectedMarket(market);
                }}
              />
              <MDDropDown
                control={control}
                className="inputField"
                label="Application"
                name="application"
                error={false}
                value={selectedApplication}
                items={['ALL', ...applications]}
                onChangeSetValue={(application: string): void => {
                  getUserOverview(selectedMarket, application, 'ALL');
                  setSelectedApplication(application);
                }}
              />
              <div style={{ marginLeft: '100px' }}>
                <MDCheckBox
                  name={'overall'}
                  value={overall ? true : false}
                  control={control}
                  onChangeSetValue={(event) => {
                    handleChangeOverall(event);
                  }}
                  label={
                    <div style={{ color: '#1578CF', fontWeight: 'bold' }}>
                      Overall
                    </div>
                  }
                />
              </div>
              <div>
                <MDCheckBox
                  name={'accepted'}
                  value={accepted ? true : false}
                  control={control}
                  onChangeSetValue={(event) => {
                    handleChangeAccepted(event);
                  }}
                  label={
                    <div style={{ color: 'green', fontWeight: 'bold' }}>
                      Accepted
                    </div>
                  }
                />
              </div>
              <div>
                <MDCheckBox
                  name={'invited'}
                  value={invited ? true : false}
                  control={control}
                  onChangeSetValue={(event) => {
                    handleChangeInvited(event);
                  }}
                  label={
                    <div
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      Invited
                    </div>
                  }
                />
              </div>
            </div>
            <LineChart
              width={1600}
              height={800}
              data={data}
              margin={{ top: 50, right: 20, bottom: 150, left: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                interval={Math.ceil(data.length / 12)} // Interval of number of labels
                tickFormatter={formatDate}
                angle={-90}
                textAnchor="end"
              >
                {' '}
              </XAxis>
              <YAxis>
                <Label value="Active users" position="insideLeft" angle={-90} />
              </YAxis>{' '}
              <Tooltip
                content={({ payload, label, active }) => {
                  if (active && payload && payload.length) {
                    // Custom tooltip content based on the data point
                    return (
                      <div style={{ textAlign: 'left' }}>
                        <p>Date: {label}</p>
                        {payload.map((entry, index) => (
                          <p key={index} style={{ color: entry.stroke }}>
                            {entry.name?.toString().replace('count', '')}:{' '}
                            {entry.value}
                          </p>
                        ))}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              {overall && (
                <Line
                  type="monotone"
                  dataKey="countOverall"
                  dot={false}
                  stroke="#1578CF"
                />
              )}
              {accepted && (
                <Line
                  type="monotone"
                  dataKey="countAccepted"
                  dot={false}
                  stroke="green"
                />
              )}
              {invited && (
                <Line
                  type="monotone"
                  dataKey="countInvited"
                  dot={false}
                  stroke="red"
                />
              )}
            </LineChart>
          </div>
        </>
      )}
    </div>
  );
};

export default UserOverview;
