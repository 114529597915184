import { ReactElement } from 'react';

import { isNullOrUndefined } from 'utils/QueryStringUtil';

import {
  EMAIL_VALIDATION_REGEX,
  REQUIRED_FIELD,
  WRONG_EMAIL_FORMAT,
} from '../../_constants_/constants';
import MDTextField from '../MDTextField/MDTextField';

interface PropType {
  control: any;
  setValue: any;
  emailLabel: string;
  emailName: string;
  emailError: any;
  emailValue: string | undefined | null;
  emailOnChange?: (value: any) => void;
}

const MDEmailField = (props: PropType): ReactElement<PropType> => {
  const validateEmail = (value: string | null) => {
    if (isNullOrUndefined(value)) {
      return true;
    } else {
      const re = EMAIL_VALIDATION_REGEX;
      return re.test(String(value).toLowerCase()) ? true : WRONG_EMAIL_FORMAT;
    }
  };

  return (
    <>
      <MDTextField
        label={props.emailLabel}
        name={props.emailName}
        control={props.control}
        setValue={props.setValue}
        error={props.emailError}
        value={props.emailValue}
        onChangeSetValue={props.emailOnChange}
        rules={{
          required: {
            message: REQUIRED_FIELD,
            value: false,
          },
          validate: validateEmail,
        }}
      />
    </>
  );
};

export default MDEmailField;
