import { ReactElement } from 'react';

const PageNotAvailable = (): ReactElement => {
  return (
    <div className="main-container">
      Sorry we could not find the page you are trying to access. Please check if
      there is an typing error in the link you used.
    </div>
  );
};

export default PageNotAvailable;
