import { ReactElement } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';

import { isNullOrUndefined } from '../../utils/GeneralUtil';

import './MDConfirmDialog.css';

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 8,
  right: 8,
  color: '#6c757d',
});

interface PropTypes {
  title?: string;
  message: string | JSX.Element | null;
  open: boolean;
  disableActions?: boolean;
  onClose?: any;
  onConfirm?: any;
}

const MDConfirmDialog = (props: PropTypes): ReactElement<PropTypes> => {
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        {!isNullOrUndefined(props.title) ? (
          <DialogTitle>{props.title}</DialogTitle>
        ) : null}
        <CloseButton onClick={props.onClose}>
          <CloseIcon />
        </CloseButton>
        <DialogContent>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {props.message}
          </Typography>
        </DialogContent>
        {props.disableActions ? null : (
          <DialogActions>
            <Button color="primary" variant="contained" onClick={props.onClose}>
              No
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={props.onConfirm}
            >
              Yes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default MDConfirmDialog;
