import React, { useEffect } from 'react';

import { MenuItem, TextField } from '@mui/material';
import { Controller, Validate } from 'react-hook-form';

import { isNullOrUndefined } from '../../utils/QueryStringUtil';

export interface DropDownItem {
  //code: string;
  value: string;
}

interface PropTypes {
  name: string;
  label: string;
  value: any;
  error: any;
  rules?:
    | Partial<{
        validate: Validate | Record<string, Validate>;
      }>
    | undefined;
  control: any;
  className?: string;
  onChangeSetValue: (value: any) => void;
  items: string[];
  validationText?: string;
  setValue?: any;
  inputProps?: any;
  InputLabelProps?: any;
}

const MDDropDown: React.FC<PropTypes> = (props: PropTypes) => {
  const { name, value, setValue } = props;

  const val = value || '';

  useEffect(() => {
    if (setValue !== undefined) {
      setValue(name, value);
    }
  }, [name, value, setValue]);

  return (
    <>
      <Controller
        control={props.control}
        render={({ onChange }) => (
          <TextField
            select
            fullWidth
            label={props.label}
            inputProps={props.inputProps}
            InputLabelProps={props.InputLabelProps}
            variant="outlined"
            helperText={props.error && props.error.message}
            error={props.error ? true : false}
            onChange={(event) => {
              props.onChangeSetValue(event.target.value);
              onChange(event.target.value);
            }}
            className={props.className}
            value={val}
          >
            {props.items?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        )}
        name={name}
        defaultValue={'All'}
        rules={
          props.validationText
            ? {
                validate: (value: any) => {
                  return !isNullOrUndefined(value)
                    ? true
                    : props.validationText;
                },
              }
            : undefined
        }
      />
    </>
  );
};

export default MDDropDown;
