import { ReactElement, useEffect, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { Button, Dialog, LinearProgress } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { basicService } from '_services_/basicService';
import { frontendService } from '_services_/frontendService';
import MDConfirmDialog from 'UI/MDConfirmDialog/MDConfirmDialog';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import MDEmailField from 'UI/MDEmailField/MDEmailField';
import MDTextField from 'UI/MDTextField/MDTextField';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const Invite = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const [userNumber, setUserNumber] = useState('');
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [number, setNumber] = useState('');

  const { errors, control, setValue } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState<string[]>([]);
  const [selectedApplication, setSelectedApplication] = useState('MORE');
  const [roles, setRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState('MORE');
  const [responseMessage, setResponseMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    frontendService
      .inviteUser(
        userNumber,
        email === '' ? null : email.trim(),
        firstname === '' ? null : firstname.trimEnd(),
        lastname === '' ? null : lastname.trimEnd(),
        number,
        selectedRole,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage('Success!');
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      //Get Applications
      basicService
        .getAllApplications()
        .then((response: AxiosResponse<string[]>): void => {
          setApplications(
            response.data.filter((str) => str !== 'BESTSELLER Login'),
          );
          basicService
            .getAllRoles()
            .then((response: AxiosResponse<string[]>): void => {
              setRoles(response.data);
              setIsLoading(false);
            });
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/Invite');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  return (
    <>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <div className="main-container">
          <>
            <Dialog open={isLoading}>
              <LinearProgress style={{ width: '200px' }} />
            </Dialog>

            <div className="inputField-root">
              <MDTextField
                className="inputField"
                control={control}
                setValue={setValue}
                label="UserNumber - Only use on edit"
                name="userNumber"
                error={errors.userNumber}
                type="number"
                value={userNumber}
                onChangeSetValue={(value: string): void => {
                  setUserNumber(value);
                }}
              />
            </div>
            <div className="inputField-root">
              <MDEmailField
                control={control}
                setValue={setValue}
                emailLabel="Email"
                emailName="email"
                emailError={errors.email}
                emailValue={email}
                emailOnChange={(value: string): void => {
                  const sanitizedValue = value.replace(/\s/g, ''); // Remove spaces
                  setEmail(sanitizedValue);
                }}
              />
            </div>
            <div className="inputField-root">
              <MDTextField
                className="inputField"
                control={control}
                setValue={setValue}
                label="Firstname"
                name="firstname"
                error={errors.firstname}
                value={firstname}
                onChangeSetValue={(value: string): void => {
                  setFirstname(value);
                }}
              />
              <MDTextField
                className="inputField"
                control={control}
                setValue={setValue}
                label="Lastname"
                name="lastname"
                error={errors.lastname}
                value={lastname}
                onChangeSetValue={(value: string): void => {
                  setLastname(value);
                }}
              />
            </div>

            <div style={{ display: 'none' }}>
              <MDDropDown
                control={control}
                className="inputField"
                label="Application"
                name="application"
                error={false}
                value={selectedApplication}
                items={applications}
                onChangeSetValue={(application: string): void => {
                  setSelectedApplication(application);
                }}
              />
            </div>
            <MDDropDown
              control={control}
              className="inputField"
              label="Role"
              name="roles"
              error={false}
              value={selectedRole}
              items={roles}
              onChangeSetValue={(role: string): void => {
                setSelectedRole(role);
              }}
            />

            <div className="inputField-root">
              <MDTextField
                className="inputField"
                control={control}
                setValue={setValue}
                label={
                  selectedRole === 'MORE' ||
                  selectedRole === 'Customer Portal User'
                    ? 'ShipTo'
                    : selectedRole === 'Customer Portal Admin' ||
                        selectedRole === 'Commerce Link'
                      ? 'Btp'
                      : selectedRole === 'Vendor Portal'
                        ? 'Supplier'
                        : ''
                }
                name="number"
                error={errors.number}
                value={number}
                onChangeSetValue={(value: string): void => {
                  setNumber(value);
                }}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              className="sendButton"
              style={{ float: 'right' }}
              type="submit"
              onClick={async () => {
                handleSubmit();
              }}
            >
              Send
              <SendIcon style={{ marginLeft: 10 }} />
            </Button>
          </>
        </div>
      )}
      {/* Popup after axios call */}
      <MDConfirmDialog
        message={responseMessage}
        open={open}
        onClose={handleClose}
        disableActions={true}
      />
    </>
  );
};

export default Invite;
