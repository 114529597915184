export const isNullOrUndefined = (
  value: any | null | undefined
): value is null | undefined => {
  return (
    value === null ||
    typeof value === 'undefined' ||
    value.length === 0 ||
    value === 0 ||
    value === ''
  );
};
