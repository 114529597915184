import { ReactElement, useEffect, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Button, Dialog, LinearProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import hljs from 'highlight.js';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { basicService } from '_services_/basicService';
import { frontendService } from '_services_/frontendService';
import { Queue_Frontend } from '_types_/QueueOutModelDto';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import MDTextField from 'UI/MDTextField/MDTextField';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import 'highlight.js/styles/default.css';

import Authentication from './Authentication';

const Queue = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const [identifier, setIdentifier] = useState(0);

  const { errors, control, setValue } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const [selectedDataType, setSelectedDataType] = useState('User_OUT');
  const [responseMessage, setResponseMessage] = useState<Queue_Frontend>();
  const [showAll, setShowAll] = useState(false);
  interface JsonObject {
    [key: string]: any;
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    console.log(identifier);
    frontendService
      .getQueueMessage(
        selectedDataType,
        identifier > 0 ? identifier : 0,
        bearerToken,
      )
      .then((response: AxiosResponse<Queue_Frontend>): void => {
        setResponseMessage(response.data);
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const setShowAllFunction = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      //Get DataTypes
      basicService
        .getAllDataTypes()
        .then((response: AxiosResponse<string[]>): void => {
          setDataTypes(response.data);
          setIsLoading(false);
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/Queue');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  useEffect(() => {
    hljs.highlightAll();
  }, []);

  const highlightJson = (json: JsonObject): string => {
    const jsonString = JSON.stringify(json, null, 4);
    const escapeHtml = (str: string) => str;
    const highlightedJson = hljs.highlight(
      'json',
      escapeHtml(jsonString),
    ).value;
    return highlightedJson;
  };

  return (
    <>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <div style={{ justifyContent: 'center' }}>
          <div className="secondViewUser-container">
            <>
              <Dialog open={isLoading}>
                <LinearProgress style={{ width: '200px' }} />
              </Dialog>

              <div className="inputField-root">
                <MDDropDown
                  control={control}
                  className="inputField"
                  label="DataType"
                  name="dtaType"
                  error={false}
                  value={selectedDataType}
                  items={dataTypes}
                  onChangeSetValue={(dataType: string): void => {
                    setSelectedDataType(dataType);
                  }}
                />
                <MDTextField
                  className="inputField"
                  control={control}
                  setValue={setValue}
                  label="Identifier"
                  name="identifier"
                  error={errors.identifier}
                  value={identifier}
                  onChangeSetValue={(value: number): void => {
                    setIdentifier(value);
                  }}
                />
              </div>
              {responseMessage !== undefined ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#f2f2f2',
                    color: 'black',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                  }}
                  type="submit"
                  //style={{ float: 'left', right: '20%' }}
                  onClick={setShowAllFunction}
                >
                  Expand and show empty and null values
                  <UnfoldMoreIcon style={{ marginLeft: 10 }} />
                </Button>
              ) : undefined}
              <Button
                variant="contained"
                color="primary"
                className="sendButton"
                style={{ float: 'right' }}
                type="submit"
                onClick={async () => {
                  handleSubmit();
                }}
              >
                Pull data
                <SendIcon style={{ marginLeft: 10 }} />
              </Button>
            </>
          </div>
        </div>
      )}

      {responseMessage !== undefined ? (
        <div style={{ justifyContent: 'center' }}>
          <div className="secondViewUser-container">
            <div>
              <br />

              <div>
                <pre>
                  <code
                    className="json"
                    dangerouslySetInnerHTML={{
                      __html: highlightJson(
                        Object.fromEntries(
                          Object.entries(
                            JSON.parse(responseMessage.message),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          ).filter(([_, value]) => {
                            if (!showAll) {
                              if (Array.isArray(value)) {
                                return value.length > 0; // Filter out empty arrays
                              } else {
                                return value !== null && value !== 0; // Filter out null and 0 values
                              }
                            }
                            return true; // Show all when showAll is false
                          }),
                        ),
                      ),
                    }}
                  />
                </pre>
              </div>
            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default Queue;
