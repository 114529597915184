export const WRONG_FORMAT = 'Wrong format';
export const WRONG_VATNUMBER_FORMAT = 'Wrong format - {{format}}';
export const WRONG_EMAIL_FORMAT = 'It must be a valid email';
export const REQUIRED_FIELD = 'This field is required';
export const CAN_NOT_BE_NEGATIVE_NUMBER = 'Cannot be a negative number';
export const CAN_ONLY_HOLD_NUMBERS = 'May only contain numbers';
export const COUNTRY_REQUIRED = 'Please select a country';
export const REGION_REQUIRED = 'Please select a region';
export const RADIO_REQUIRED = 'Please select an option';
export const CHECKBOX_REQUIRED = 'Confirmation is required';

export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CUSTOMER_NUMBER = /^[0]{1}$|^[0-9]{5,6}$/;
