import { ReactElement, useEffect, useState } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { basicService } from '_services_/basicService';
import { graphService } from '_services_/graphService';
import InviteOverviewDto from '_types_/InviteOverviewDto';
import MDCheckBox from 'UI/MDCheckBox/MDCheckBox';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const InviteOverview = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const { control } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // Main data
  const [data, setData] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);

  // Market
  const [markets, setMarkets] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState('ALL');

  // Application
  const [selectedApplication, setSelectedApplication] =
    useState('BESTSELLER Login');
  const [applications, setApplications] = useState<string[]>([]);

  // Checkbox
  const [overallInvited, setOverallInvited] = useState(true);
  const [accepted, setAccepted] = useState(true);
  const [invited, setInvited] = useState(true);

  // InfoBox
  const [infoboxTitle, setInfoboxTitle] = useState('');
  const [infoboxBody, setinfoboxBody] = useState('');

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${month} ${date.getDate()} - ${year}`;
  };

  const setInfobox = (chartData: any) => {
    const timestamps = chartData.map((data: any) =>
      new Date(data.executionDate).getTime(),
    );

    // Find the data object with the newest date
    const newestData = chartData.find(
      (data: any) =>
        new Date(data.executionDate).getTime() === Math.max(...timestamps),
    );

    setInfoboxTitle('Status of Today');
    setinfoboxBody(
      'Updates once a day \n early morning.\n\n' +
        'Invited: ' +
        newestData?.Invited +
        '\n' +
        'Accepted: ' +
        newestData?.Accepted +
        '\n' +
        'Pending: ' +
        newestData?.Pending,
    );
  };

  // Handle graph-data
  const getInviteOverview = async (
    graphData: any[],
    market: any,
    application: any,
  ) => {
    setIsLoading(true);

    // Group the data by excecutionDate
    const transformedData = graphData.reduce((acc: any, currentItem: any) => {
      if (
        (currentItem.applicationName === application ||
          application === 'ALL') &&
        (currentItem.marketName === market || market === 'ALL')
      ) {
        const { excecutionDate, totalCount, invitedCount, acceptedCount } =
          currentItem;

        // Initialize a new entry if it doesn't exist in the result
        if (!acc[excecutionDate]) {
          acc[excecutionDate] = {
            totalCount: 0,
            invitedCount: 0,
            acceptedCount: 0,
          };
        }

        // Update the result with aggregated (summed up) data
        acc[excecutionDate].totalCount += totalCount;
        acc[excecutionDate].invitedCount += invitedCount;
        acc[excecutionDate].acceptedCount += acceptedCount;
      }
      return acc;
    }, {});

    // Map the transformed data to chartData format
    const chartData = Object.keys(transformedData).map((executionDate) => ({
      executionDate,
      Invited: transformedData[executionDate].totalCount,
      Pending: transformedData[executionDate].invitedCount,
      Accepted: transformedData[executionDate].acceptedCount,
    }));

    setChartData(chartData);
    setInfobox(chartData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      // Get Markets
      basicService
        .getAllMarkets()
        .then((response: AxiosResponse<string[]>): void => {
          setMarkets(response.data);
        });
      // Get Applications
      basicService
        .getAllApplications()
        .then((response: AxiosResponse<string[]>): void => {
          setApplications(
            response.data.filter(
              (str) => str !== 'Vendor Portal' && str !== 'ALL',
            ),
          );
        });

      // Get Graph data
      graphService
        .getInviteOverview(bearerToken_temp)
        .then((response: AxiosResponse<InviteOverviewDto[]>): void => {
          getInviteOverview(response.data, 'ALL', 'BESTSELLER Login');
          setData(response.data);
        })
        .finally((): void => {
          setIsLoading(false);
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/InviteOverview');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  const handleChangeOverallInvited = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setOverallInvited(event.target.checked);
  };
  const handleChangeAccepted = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setAccepted(event.target.checked);
  };
  const handleChangeInvited = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setInvited(event.target.checked);
  };

  return (
    <>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <div className="search" style={{ marginTop: '10px' }}>
          <>
            <Dialog open={isLoading}>
              <LinearProgress style={{ width: '200px' }} />
            </Dialog>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <div
                style={{
                  alignItems: 'center',
                  width: '900px',
                  display: 'inline-flex',
                }}
              >
                <MDDropDown
                  control={control}
                  className="inputField"
                  label="Market"
                  name="market"
                  error={false}
                  value={selectedMarket}
                  items={['ALL', ...markets]}
                  onChangeSetValue={(market: string): void => {
                    getInviteOverview(data, market, selectedApplication);
                    setSelectedMarket(market);
                  }}
                />
                <MDDropDown
                  control={control}
                  className="inputField"
                  label="Application"
                  name="application"
                  error={false}
                  value={selectedApplication}
                  items={[...applications]}
                  onChangeSetValue={(application: string): void => {
                    getInviteOverview(data, selectedMarket, application);
                    setSelectedApplication(application);
                  }}
                />
                <div style={{ marginLeft: '100px' }}>
                  <MDCheckBox
                    name={'Invited'}
                    value={overallInvited ? true : false}
                    control={control}
                    onChangeSetValue={(event) => {
                      handleChangeOverallInvited(event);
                    }}
                    label={
                      <div style={{ color: '#1578CF', fontWeight: 'bold' }}>
                        Invited
                      </div>
                    }
                  />
                </div>
                <div>
                  <MDCheckBox
                    name={'accepted'}
                    value={accepted ? true : false}
                    control={control}
                    onChangeSetValue={(event) => {
                      handleChangeAccepted(event);
                    }}
                    label={
                      <div style={{ color: 'green', fontWeight: 'bold' }}>
                        Accepted
                      </div>
                    }
                  />
                </div>
                <div>
                  <MDCheckBox
                    name={'Pending'}
                    value={invited ? true : false}
                    control={control}
                    onChangeSetValue={(event) => {
                      handleChangeInvited(event);
                    }}
                    label={
                      <div
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        Pending
                      </div>
                    }
                  />
                </div>
              </div>
              <LineChart
                width={1600}
                height={800}
                data={chartData}
                margin={{ top: 50, right: 20, bottom: 100, left: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="executionDate"
                  interval={Math.ceil(chartData.length / 12)} // Interval of number of labels
                  tickFormatter={formatDate}
                  angle={-90}
                  textAnchor="end"
                >
                  {' '}
                </XAxis>
                <YAxis>
                  <Label
                    value="Active users"
                    position="insideLeft"
                    angle={-90}
                  />
                </YAxis>{' '}
                <Tooltip
                  content={({ payload, label, active }) => {
                    if (active && payload && payload.length) {
                      // Custom tooltip content based on the data point
                      return (
                        <div style={{ textAlign: 'left' }}>
                          <p>Date: {label}</p>
                          {payload.map((entry, index) => (
                            <p key={index} style={{ color: entry.stroke }}>
                              {entry.name?.toString().replace('count', '')}:{' '}
                              {entry.value}
                            </p>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                {overallInvited && (
                  <Line
                    type="monotone"
                    dataKey="Invited"
                    dot={false}
                    stroke="#1578CF"
                  />
                )}
                {accepted && (
                  <Line
                    type="monotone"
                    dataKey="Accepted"
                    dot={false}
                    stroke="green"
                  />
                )}
                {invited && (
                  <Line
                    type="monotone"
                    dataKey="Pending"
                    dot={false}
                    stroke="red"
                  />
                )}
              </LineChart>
            </div>
            {
              <div
                className="infobox"
                style={{
                  whiteSpace: 'pre-line',
                  backgroundColor: 'transparent',
                  textAlign: 'right',
                  width: '180px',
                  height: '200px',
                }}
              >
                <h2>{infoboxTitle}</h2>
                <p>{infoboxBody}</p>
              </div>
            }
          </>
        </div>
      )}
    </>
  );
};

export default InviteOverview;
