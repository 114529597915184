import { ReactElement } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { CUSTOMER_NUMBER, REQUIRED_FIELD } from '_constants_/constants';
import {
  SET_USERCRITERIA_ADINVITESTATUS,
  SET_USERCRITERIA_APPLICATION,
  SET_USERCRITERIA_BTPNUMBER,
  SET_USERCRITERIA_EMAIL,
  SET_USERCRITERIA_FIRSTNAME,
  SET_USERCRITERIA_LANGUAGE,
  SET_USERCRITERIA_LASTNAME,
  SET_USERCRITERIA_MARKET,
  SET_USERCRITERIA_SHIPTONUMBER,
  SET_USERCRITERIA_INVOICEACCOUNTNUMBER,
  SET_USERCRITERIA_SUPPLIERNUMBER,
  SET_USERCRITERIA_USERSTYPE,
} from '_reducers_/SearchForUserReducer';
import { FetchDataAction } from '_types_/actions/FetchDataAction';
import { SearchForUserAction } from '_types_/actions/SearchForUserAction';
//import MDAutoComplete from 'UI/MDAutoComplete/MDAutoComplete';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import MDEmailField from 'UI/MDEmailField/MDEmailField';
import MDTextField from 'UI/MDTextField/MDTextField';

interface PropType {
  control: any;
  errors: any;
  trigger: any;
  formState: any;
  setValue: any;
  isLoading: boolean;
}

const UserSearchComponent = (props: PropType): ReactElement<PropType> => {
  const { control, errors, setValue, isLoading } = props;

  const searchForUser = useSelector(
    (state: { SearchForUser: SearchForUserAction }) => state.SearchForUser,
  );

  const fetchData = useSelector(
    (state: { FetchData: FetchDataAction }) => state.FetchData,
  );

  const dispatch = useDispatch();

  return (
    <>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>

      <div className="inputField-root">
        <MDTextField
          className="inputField"
          control={control}
          setValue={setValue}
          label="First name"
          name="firstname"
          error={errors.firstname}
          value={searchForUser.UserSearchCriteria.firstname}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_USERCRITERIA_FIRSTNAME,
              UserSearchCriteria: { firstname: e },
            });
          }}
          rules={{
            required: { message: REQUIRED_FIELD, value: false },
          }}
        />
        <MDTextField
          className="inputField"
          control={control}
          setValue={setValue}
          label="Lastname"
          name="lastname"
          error={errors.lastname}
          value={searchForUser.UserSearchCriteria.lastname}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_USERCRITERIA_LASTNAME,
              UserSearchCriteria: { lastname: e },
            });
          }}
          rules={{
            required: { message: REQUIRED_FIELD, value: false },
          }}
        />
      </div>

      <div className="inputField-root">
        <MDDropDown
          control={control}
          className="inputField"
          label="Language"
          name="language"
          error={errors.language}
          value={searchForUser.UserSearchCriteria.language}
          items={['All', ...fetchData.FetchData.allLanguages]}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_USERCRITERIA_LANGUAGE,
              UserSearchCriteria: { language: e },
            });
          }}
        />
      </div>

      <MDEmailField
        control={control}
        setValue={setValue}
        emailLabel="Email"
        emailName="email"
        emailError={errors.email}
        emailValue={searchForUser.UserSearchCriteria.email}
        emailOnChange={(e: string): void => {
          const sanitizedEmail = e.replace(/\s/g, ''); // Removes all spaces
          dispatch({
            type: SET_USERCRITERIA_EMAIL,
            UserSearchCriteria: { email: sanitizedEmail },
          });
        }}
      />

      <div className="inputField-root">
        <MDDropDown
          control={control}
          className="inputField"
          label="Invite Status"
          name="adInviteStatus"
          error={errors.adInviteStatus}
          value={searchForUser.UserSearchCriteria.adInviteStatus}
          items={['All', 'Invited', 'Accepted']}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_USERCRITERIA_ADINVITESTATUS,
              UserSearchCriteria: { adInviteStatus: e },
            });
          }}
        />

        {/* <MDDropDown
          control={control}
          className="inputField"
          label="Application"
          name="application"
          error={errors.application}
          value={searchForUser.UserSearchCriteria.application}
          items={[
            'All',
            ...fetchData.FetchData.allApplications.filter(
              (str) => str === 'Vendor Portal',
            ),
          ]}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_USERCRITERIA_APPLICATION,
              UserSearchCriteria: { application: e },
            });
          }}
        /> */}
      </div>
      <MDTextField
        control={control}
        setValue={setValue}
        className="inputField"
        label="Supplier Number"
        name="supplierNumber"
        error={errors.supplierNumber}
        value={searchForUser.UserSearchCriteria.supplierNumber}
        onChangeSetValue={(e: string): void => {
          dispatch({
            type: SET_USERCRITERIA_SUPPLIERNUMBER,
            UserSearchCriteria: { supplierNumber: Number(e) },
          });
        }}
        rules={{
          required: { message: REQUIRED_FIELD, value: false },
          pattern: {
            value: CUSTOMER_NUMBER,
            message: 'Invalid number',
          },
        }}
      />
    </>
  );
};

export default UserSearchComponent;
