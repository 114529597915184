import { createRef, ReactElement, useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';

import { usermasterAPI } from '_services_/usermasterAPI';
import { SearchForBtpAction } from '_types_/actions/SearchForBtpAction';
import { PersonModelDto } from '_types_/PersonModelDto';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';
import { getAllApplications, getAllMarkets } from 'utils/UserSearchUtil';

import Authentication from './Authentication';
import BtpSearchComponent from './BtpSearchComponent';

const BtpSearch = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const [data, setData] = useState<PersonModelDto[]>([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage] = useState(20);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const options: MUIDataTableOptions = {
    print: false,
    search: true,
    filterType: 'multiselect',
    responsive: 'vertical',
    count: count,
    selectableRows: 'none',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [15, 20, 100],
    onCellClick(colData, cellMeta) {
      if (cellMeta.colIndex === 7) {
        window.open(
          '/Contact/' + data[cellMeta.dataIndex].personNumber,
          '_blank',
        );
      }
    },
    sort: false,
    resizableColumns: false,
    onFilterChange: (
      columnChanged,
      filterList,
      type,
      changedColumnChanged,
      displayData,
    ) => {
      setCount(displayData?.length);
    },
    download: true,
    downloadOptions: {
      filename: 'Btp_Overview.csv',
      separator: ',',
    },
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('auth');

      getAllApplications(setIsLoading, dispatch);
      getAllMarkets(setIsLoading, dispatch);

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/BtpSearch');
    }
  }, [auth, dispatch]);

  const [columns] = useState([
    {
      name: 'personNumber',
      label: 'PersonNumber',
      options: {
        sort: false,
        customBodyRender: (value: number) => {
          return value === 0 ? '' : value.toString();
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value;
        },
      },
    },
    // {
    //   name: 'firstname',
    //   label: 'Firstname',
    //   options: {
    //     sort: false,
    //     customBodyRender: (value: any) => {
    //       return value;
    //     },
    //   },
    // },
    // {
    //   name: 'lastName',
    //   label: 'Lastname',
    //   options: {
    //     sort: false,
    //     customBodyRender: (value: any) => {
    //       return value;
    //     },
    //   },
    // },
    {
      name: 'language',
      label: 'Language',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value;
        },
      },
    },
    {
      name: 'btpNumber',
      label: 'BtpNumber',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value;
        },
      },
    },
    {
      name: 'adInviteStatus',
      label: 'Invite Status',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value;
        },
      },
    },
    {
      name: 'inviteSend',
      label: 'Invite send',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: string) => {
          return value === null ? value : value.split('T')[0];
        },
      },
    },
    {
      name: 'deleted',
      label: 'Deleted',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value ? 'Yes' : 'No';
        },
      },
    },
    {
      name: 'open',
      label: 'Open',
      style: {
        cursor: 'pointer',
      },
      options: {
        sort: false,
        customBodyRender: () => {
          return (
            <div style={{ cursor: 'pointer' }}>
              <SettingsIcon />
            </div>
          );
        },
      },
    },
  ]);

  const { trigger, formState, errors, control, handleSubmit, setValue } =
    useForm();

  const searchForBtp = useSelector(
    (state: { SearchForBtp: SearchForBtpAction }) => state.SearchForBtp,
  );

  const form = createRef<ValidatorForm>();

  const handleBtpCriteria = (): void => {
    if (form.current && !form.current.isFormValid(false)) {
      return;
    } else {
      let criterias = searchForBtp.BtpSearchCriteria;

      if (criterias.market === 'All') {
        criterias = { ...criterias, market: null };
      }
      console.log(criterias);
      setIsLoading(true);
      usermasterAPI
        .getSearchedBtps(criterias, bearerToken)
        .then((response: AxiosResponse<PersonModelDto[]>): void => {
          setData(response.data);
          setCount(response.data.length);
        })
        .catch((error: AxiosError): void => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return bearerToken === '' ? (
    <Authentication />
  ) : (
    <div>
      <form onSubmit={handleSubmit(handleBtpCriteria)}>
        <div className="main-container">
          <BtpSearchComponent
            control={control}
            trigger={trigger}
            errors={errors}
            formState={formState}
            setValue={setValue}
            isLoading={isLoading}
          />
          <Button
            variant="contained"
            color="primary"
            className="sendButton"
            style={{ float: 'right' }}
            type="submit"
          >
            Search
            <Search style={{ marginLeft: 10 }} />
          </Button>
        </div>
      </form>
      <div className="search">
        <MUIDataTable
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '30px 0px',
              }}
            >
              <h2 style={{ margin: '0' }}>Btp overview</h2>
            </div>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default BtpSearch;
