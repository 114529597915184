import { makeStyles } from '@mui/styles';

export const usePaperStyles = makeStyles(() => ({
  paper: {
    textAlign: 'left',
    color: 'black',
    boxShadow: 'none',
    borderRadius: 0,
  },
  noShadow: {
    boxShadow: 'none',
    borderRadius: 0,
  },
  TextValidator: {
    width: '100%',
    textAlign: 'left',
  },
  Tabel: {
    overflowX: 'auto',
    width: '100%',
  },
}));
