import { ReactElement, useEffect, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { Button, Dialog, LinearProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { basicService } from '_services_/basicService';
import { frontendService } from '_services_/frontendService';
import MDConfirmDialog from 'UI/MDConfirmDialog/MDConfirmDialog';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import MDTextField from 'UI/MDTextField/MDTextField';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const QueueHandler = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();

  const { errors, control, setValue } = useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const [selectedDataType, setSelectedDataType] = useState('User_OUT');
  const [identifier, setIdentifier] = useState(0);
  const [count, setCount] = useState(0);
  const [active, setActive] = useState('All');
  const [responseMessage, setResponseMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [applications, setApplications] = useState<string[]>([]);
  const [dropDownApplications, setDropdownApplications] = useState<string[]>(
    [],
  );
  const [showApplication, setShowApplication] = useState(true);
  const [showActive, setShowActive] = useState(true);

  const [selectedApplication, setSelectedApplication] = useState('All');

  const [infoMessage, setInfoMessage] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const handleCloseInfo = () => setOpenInfo(false);

  const handleSubmit = async (confirmed: boolean) => {
    setIsLoading(true);
    frontendService
      .sendOutQueue(
        selectedDataType,
        showApplication ? selectedApplication : 'All',
        identifier,
        active === 'Only Active' ? true : false,
        confirmed,
        count,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then((response: AxiosResponse<string>): void => {
        if (confirmed) {
          setInfoMessage(response.data.toString().replace(/^"|"$/g, ''));
          setOpenInfo(true);
          handleClose();
        } else {
          setResponseMessage(response.data.toString().replace(/^"|"$/g, ''));
          setOpen(true);
        }
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const HandleDropdownApplications = async () => {
    switch (selectedDataType) {
      case 'User_OUT':
        setDropdownApplications(
          applications.filter((item) => !item.includes('Vendor')),
        );
        setShowApplication(true);
        setShowActive(true);
        break;

      case 'SupplierUser_OUT':
        setDropdownApplications(
          applications.filter((item) => item.includes('Vendor')),
        );
        setShowApplication(true);
        setShowActive(true);
        break;
      case 'ContactPerson_OUT':
        setShowApplication(false);
        setShowActive(true);
        break;

      default:
        setShowApplication(false);
        setShowActive(false);
        break;
    }
  };

  useEffect(() => {
    HandleDropdownApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataType]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      //Get DataTypes
      basicService
        .getAllDataTypes()
        .then((response: AxiosResponse<string[]>): void => {
          setDataTypes(response.data.filter((item) => !item.includes('_IN')));

          //Get Applications
          basicService
            .getAllApplications()
            .then((response: AxiosResponse<string[]>): void => {
              setApplications(
                response.data.filter((item) => item !== 'BESTSELLER Login'),
              );
              setDropdownApplications(
                response.data.filter(
                  (item) =>
                    !item.includes('Vendor') && item !== 'BESTSELLER Login',
                ),
              );
              setIsLoading(false);
            });
        });

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/QueueHandler');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  return (
    <>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <div style={{ justifyContent: 'center' }}>
          <div className="secondViewUser-container">
            <>
              <Dialog open={isLoading}>
                <LinearProgress style={{ width: '200px' }} />
              </Dialog>

              <div className="inputField-root">
                <MDDropDown
                  control={control}
                  className="inputField"
                  label="DataType"
                  name="dtaType"
                  error={false}
                  value={selectedDataType}
                  items={dataTypes}
                  onChangeSetValue={(dataType: string): void => {
                    setSelectedDataType(dataType);
                  }}
                />
                {showApplication ? (
                  <MDDropDown
                    control={control}
                    className="inputField"
                    label="Application"
                    name="application"
                    error={false}
                    value={selectedApplication}
                    items={['All', ...dropDownApplications]}
                    onChangeSetValue={(application: string): void => {
                      setSelectedApplication(application);
                    }}
                  />
                ) : undefined}
                <MDTextField
                  className="inputField"
                  control={control}
                  setValue={setValue}
                  label="Identifier"
                  name="identifier"
                  error={errors.identifier}
                  value={identifier}
                  onChangeSetValue={(value: number): void => {
                    setIdentifier(value);
                  }}
                />
                {showActive ? (
                  <MDDropDown
                    control={control}
                    className="inputField"
                    label="Active data"
                    name="active"
                    error={false}
                    value={active}
                    items={['Only Active', 'All']}
                    onChangeSetValue={(active: string): void => {
                      setActive(active);
                    }}
                  />
                ) : undefined}
                <MDTextField
                  className="inputField"
                  control={control}
                  setValue={setValue}
                  label="Count?"
                  name="count"
                  error={errors.count}
                  value={count}
                  onChangeSetValue={(value: number): void => {
                    setCount(value);
                  }}
                />
              </div>

              <Button
                variant="contained"
                color="primary"
                className="sendButton"
                style={{ float: 'right' }}
                type="submit"
                onClick={async () => {
                  handleSubmit(false);
                }}
              >
                Update Queue
                <SendIcon style={{ marginLeft: 10 }} />
              </Button>
            </>
          </div>
        </div>
      )}
      {
        <MDConfirmDialog
          message={responseMessage}
          open={open}
          onClose={handleClose}
          disableActions={false}
          onConfirm={() => handleSubmit(true)}
        />
      }
      {
        <MDConfirmDialog
          message={infoMessage}
          open={openInfo}
          onClose={handleCloseInfo}
          disableActions={true}
        />
      }
    </>
  );
};

export default QueueHandler;
