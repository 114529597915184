import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import ReactDOM from 'react-dom';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';

import { getAppRegistrationClientId } from 'utils/QueryStringUtil';

import Store from './_store_/Store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

const oidcConfig = {
  authority:
    'https://login.microsoftonline.com/98db9fb9-f52b-4e63-83d9-795ccd2dfcca/v2.0',
  client_id: getAppRegistrationClientId(),
  redirect_uri: window.location.origin + '/auth',
};

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'span',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: `AdelleBSOffice`,
          // fontSize: "16px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { fontFamily: `AdelleBSOffice` },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: `AdelleBSOffice`,
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { boxShadow: 'none', borderRadius: 0 },
        contained: { boxShadow: 'none' },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { marginBottom: '17px' },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: { fontWeight: 'bold' },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        chip: { margin: '8px 8px 8px 0px' },
      },
    },
  },
  palette: {
    background: {
      default: '#E0E0E0',
    },

    primary: {
      main: '#757575',
    },

    secondary: {
      main: '#3D3A3B',
    },
  },
  typography: {
    fontFamily: `AdelleBSOffice`,
    // fontSize: 16,
    h6: { fontFamily: `AdelleBSOffice`, color: 'black' },
    body1: {
      fontFamily: `AdelleBSOffice`,
      fontSize: 16,
    },
  },
});

ReactDOM.render(
  <Provider store={Store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AuthProvider {...oidcConfig}>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
