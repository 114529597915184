import axios, { AxiosPromise } from 'axios';

import { BtpSearchCriteriaClass } from '_types_/BtpSearchCriteriaClass';
import { PersonModelDto } from '_types_/PersonModelDto';
import { PersonSearchCriteriaClass } from '_types_/PersonSearchCriteriaClass';
import { UserModelDto } from '_types_/UserModelDto';
import { UserSearchCriteriaClass } from '_types_/UserSearchCriteriaClass';

import { properties } from '../properties';

const requestOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const getSearchedUsers = (
  UserSearchCriteria: UserSearchCriteriaClass,
  bearerToken: string
): AxiosPromise<UserModelDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'User/Get/SearchedUsers',
    method: 'post',
    data: JSON.stringify(UserSearchCriteria),
    headers: headers,
    withCredentials: true,
  });
};

const getSearchedPersons = (
  PersonSearchCriteria: PersonSearchCriteriaClass,
  bearerToken: string
): AxiosPromise<PersonModelDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'User/Get/SearchedPersons',
    method: 'post',
    data: JSON.stringify(PersonSearchCriteria),
    headers: headers,
    withCredentials: true,
  });
};

const getSearchedBtps = (
  PersonSearchCriteria: BtpSearchCriteriaClass,
  bearerToken: string
): AxiosPromise<PersonModelDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'User/Get/SearchedBtps',
    method: 'post',
    data: JSON.stringify(PersonSearchCriteria),
    headers: headers,
    withCredentials: true,
  });
};

export const usermasterAPI = {
  getSearchedUsers,
  getSearchedPersons,
  getSearchedBtps,
};
