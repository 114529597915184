import { ReactElement, useEffect, useState } from 'react';

import axios, { AxiosError, AxiosPromise } from 'axios';

import { properties } from 'properties';

export interface AcceptAdGuid {
  adGuid?: string;
}

let errorMessage = 'Error! Contact service';
let succesMessage = 'You will be forwarded, wait...';

const AcceptUser = (): ReactElement => {
  const [succesfullyAccepted, setSuccesfullyAccepted] = useState(false);
  const [errorOnAccepted, setErrorOnAccepted] = useState(false);

  useEffect(() => {
    const requestOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const setUserAsAcceptedApi = (
      AcceptAdguid: AcceptAdGuid,
    ): AxiosPromise<string> => {
      return axios({
        url: properties.serviceApiURL + 'User/UserAccepted',
        method: 'post',
        headers: requestOptions.headers,
        data: JSON.stringify(AcceptAdguid),
      });
    };

    const handleSetUserAsAcceptedApi = (AcceptAdguid: AcceptAdGuid) => {
      setUserAsAcceptedApi(AcceptAdguid)
        .then((response) => {
          if (response.data === 'OK') {
            succesMessage = 'You have accepted';
            setSuccesfullyAccepted(true);
          } else if (response.data === 'Expired') {
            errorMessage =
              'Your invitation has expired, either because a new invitation has been sent to you or because the time limit for accepting the invitation has expired, check your inbox for a new invitation or contact support';
            setErrorOnAccepted(true);
          } else if (response.data === 'No ad-account found') {
            errorMessage =
              'You have no access with this account, please contact support';
            setErrorOnAccepted(true);
          } else {
            setSuccesfullyAccepted(true);
            window.location.replace(response.data);
          }
        })
        .catch((error: AxiosError): void => {
          const status = error.response ? error.response.status : -1;
          errorMessage = 'Contact Support - (API status error: ' + status + ')';
          setErrorOnAccepted(true);
        })
        .finally(() => {});
    };

    const au: AcceptAdGuid = {
      adGuid: window.location.pathname.split('/', 3)[2],
    };
    handleSetUserAsAcceptedApi(au);
  }, []);

  return (
    <>
      {succesfullyAccepted ? (
        <>
          <p>{succesMessage}</p>
        </>
      ) : errorOnAccepted ? (
        <>
          <p>{errorMessage}</p>
        </>
      ) : (
        <>
          <p>Please wait</p>
        </>
      )}
    </>
  );
};

export default AcceptUser;
