import { SearchForPersonAction } from '../_types_/actions/SearchForPersonAction';

export const SET_PERSONCRITERIA_FIRSTNAME = 'SET_PERSONCRITERIA_FIRSTNAME';
export const SET_PERSONCRITERIA_LASTNAME = 'SET_PERSONCRITERIA_LASTNAME';
export const SET_PERSONCRITERIA_LANGUAGE = 'SET_PERSONCRITERIA_LANGUAGE';
export const SET_PERSONCRITERIA_MARKET = 'SET_PERSONCRITERIA_MARKET';
export const SET_PERSONCRITERIA_EMAIL = 'SET_PERSONCRITERIA_EMAIL';
export const SET_PERSONCRITERIA_ADINVITESTATUS =
  'SET_PERSONCRITERIA_ADINVITESTATUS';
export const SET_PERSONCRITERIA_APPLICATION = 'SET_PERSONCRITERIA_APPLICATION';
export const GET_PERSONCRITERIA_BTPS = 'GET_PERSONCRITERIA_BTPS';
export const SET_PERSONCRITERIA_BTPS = 'SET_PERSONCRITERIA_BTPS';
export const SET_PERSONCRITERIA_BTPNUMBER = 'SET_PERSONCRITERIA_BTPNUMBER';
export const SET_PERSONCRITERIA_SUPPLIERNUMBER =
  'SET_PERSONCRITERIA_SUPPLIERNUMBER';
export const SET_PERSONCRITERIA_SHIPTONUMBER =
  'SET_PERSONCRITERIA_SHIPTONUMBER';

export const searchForPersonActionInitialState: SearchForPersonAction = {
  type: '',
  PersonSearchCriteria: {
    firstname: null,
    lastname: null,
    email: null,
    market: 'All',
    application: 'None',
    btpNumber: 0,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SearchForPersonReducer = (
  state = searchForPersonActionInitialState,
  action: SearchForPersonAction
) => {
  switch (action.type) {
    case SET_PERSONCRITERIA_FIRSTNAME: {
      let firstnameValue: string | null = '';
      if (action.PersonSearchCriteria.firstname === '') {
        firstnameValue = null;
      } else {
        firstnameValue = action.PersonSearchCriteria.firstname;
      }
      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          firstname: firstnameValue,
        },
      };
    }
    case SET_PERSONCRITERIA_LASTNAME: {
      let lastnameValue: string | null = '';
      if (action.PersonSearchCriteria.lastname === '') {
        lastnameValue = null;
      } else {
        lastnameValue = action.PersonSearchCriteria.lastname;
      }
      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          lastname: lastnameValue,
        },
      };
    }

    case SET_PERSONCRITERIA_MARKET: {
      let marketValue: string | null = '';
      if (action.PersonSearchCriteria.market === '') {
        marketValue = null;
      } else {
        marketValue = action.PersonSearchCriteria.market;
      }
      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          market: marketValue,
        },
      };
    }

    case SET_PERSONCRITERIA_APPLICATION: {
      let applicationValue: string | null = '';
      if (action.PersonSearchCriteria.application === '') {
        applicationValue = null;
      } else {
        applicationValue = action.PersonSearchCriteria.application;
      }
      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          application: applicationValue,
        },
      };
    }

    case SET_PERSONCRITERIA_BTPS: {
      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          btpNumber: action.PersonSearchCriteria.btpNumber,
        },
      };
    }

    case SET_PERSONCRITERIA_EMAIL: {
      let emailValue: string | null = '';
      if (action.PersonSearchCriteria.email === '') {
        emailValue = null;
      } else {
        emailValue = action.PersonSearchCriteria.email;
      }
      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          email: emailValue,
        },
      };
    }

    case SET_PERSONCRITERIA_BTPNUMBER: {
      let btpNumberValue: number | null = 0;

      if (isNaN(action.PersonSearchCriteria.btpNumber)) {
        btpNumberValue = 0;
      } else {
        btpNumberValue = action.PersonSearchCriteria.btpNumber;
      }

      return {
        ...state,
        PersonSearchCriteria: {
          ...state.PersonSearchCriteria,
          btpNumber: btpNumberValue,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default SearchForPersonReducer;
