export const isNullOrUndefined = (
  value: any | null | undefined
): value is null | undefined => {
  return (
    value === null ||
    typeof value === 'undefined' ||
    value.length === 0 ||
    value === 0 ||
    value === ''
  );
};

export const getAppRegistrationClientId = (): string => {
  const origin = window.location.origin;
  if (origin.includes('usermaster-test')) {
    return '9c87c289-bc38-4f3c-840e-e92c0f0ea6db';
  } else if (
    origin.includes('usermaster-dev') ||
    origin.includes('localhost')
  ) {
    return '01b2dd82-f2e6-4927-b632-b98482c0372f';
  } else {
    return '1c082a95-f7bf-4b9a-be0a-87f203715720';
  }
};
