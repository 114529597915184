import axios, { AxiosPromise } from 'axios';

import { ContactFrontendDto } from '_types_/ContactFrontendModelDto';
import { FolderInfo } from '_types_/FolderInfo';

import { properties } from '../properties';

const requestOptions = {
  headers: {
    Accept: 'text/plain',
    'Content-Type': 'text/plain',
    'Access-Control-Allow-Origin': '*',
  },
};

const getAllApplications = (): AxiosPromise<string[]> => {
  return axios({
    url: properties.serviceApiURL + 'BasicData/Applications',
    method: 'get',
    headers: requestOptions.headers,
  });
};

const getAllDataTypes = (): AxiosPromise<string[]> => {
  return axios({
    url: properties.serviceApiURL + 'BasicData/DataTypes',
    method: 'get',
    headers: requestOptions.headers,
  });
};

const getAllRoles = (): AxiosPromise<string[]> => {
  return axios({
    url: properties.serviceApiURL + 'BasicData/Roles',
    method: 'get',
    headers: requestOptions.headers,
  });
};

const getAllLanguages = (): AxiosPromise<string[]> => {
  return axios({
    url: properties.serviceApiURL + 'BasicData/Languages',
    method: 'get',
    headers: requestOptions.headers,
  });
};

const getAllMarkets = (): AxiosPromise<string[]> => {
  return axios({
    url: properties.serviceApiURL + 'BasicData/Markets',
    method: 'get',
    headers: requestOptions.headers,
  });
};

// const getAllBTPs = (): AxiosPromise<number[]> => {
//   return axios({
//     url: properties.serviceApiURL + 'UserSearch/GetBusinessTradingPartners',
//     method: 'get',
//     headers: requestOptions.headers,
//   });
// };

const getContact = (
  personNumber: string | null,
  bearerToken: string,
): AxiosPromise<ContactFrontendDto> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'BasicFrontend/Get/Contact',
    method: 'post',
    data: personNumber,
    headers: headers,
    withCredentials: true,
  });
};

const getFolderInfo = (): AxiosPromise<FolderInfo[]> => {
  const headers = {
    ...requestOptions.headers,
  };

  return axios({
    url: properties.serviceApiURL + 'BasicFrontend/Get/EmailList',
    method: 'post',
    headers: headers,
  });
};

export const basicService = {
  getFolderInfo,
  getAllApplications,
  getAllDataTypes,
  getAllRoles,
  getAllLanguages,
  getAllMarkets,
  getContact,
};
