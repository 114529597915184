import { ReactElement, useEffect, useState } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';

import { basicService } from '_services_/basicService';
import { ContactFrontendDto } from '_types_/ContactFrontendModelDto';
import MDEmailField from 'UI/MDEmailField/MDEmailField';
import MDTextField from 'UI/MDTextField/MDTextField';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const ContactView = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const [data_contact, setData] = useState<ContactFrontendDto>();
  const { control, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;

      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      setIsLoading(true);
      basicService
        .getContact(window.location.pathname.split('/', 3)[2], bearerToken_temp)
        .then((response: AxiosResponse<ContactFrontendDto>): void => {
          setData(response.data);
        })
        .finally((): void => {
          setIsLoading(false);
        });
    } else {
      setWithExpiry(
        'auth',
        '/Contact/' + window.location.pathname.split('/', 3)[2]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const [showTooltip, setShowTooltip] = useState(false);
  const [infoboxTitle, setInfoboxTitle] = useState('');
  const [infoboxBody, setinfoboxBody] = useState('');

  const handleMouseEnter = (event: any, title: string, body: string) => {
    setInfoboxTitle(title);
    setinfoboxBody(body);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return bearerToken === '' ? (
    <Authentication />
  ) : (
    <div>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>
      <div className={'mainViewUser-container'}>
        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="First name"
            name="firstname"
            error={data_contact?.deleted}
            value={data_contact?.firstname}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Lastname"
            name="lastname"
            error={data_contact?.deleted}
            value={data_contact?.lastName}
          />
        </div>

        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel="Email"
          emailName="email"
          emailError={data_contact?.deleted}
          emailValue={data_contact?.email}
        />
        <MDTextField
          control={control}
          setValue={setValue}
          label="Language"
          name="language"
          error={data_contact?.deleted}
          value={data_contact?.language}
        />

        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Person Number"
            name="personNumber"
            error={data_contact?.deleted}
            value={data_contact?.personNumber}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="User Number"
            name="userNumber"
            error={data_contact?.deleted}
            value={data_contact?.userNumber}
            onClick={() =>
              data_contact?.userNumber.toString().length === 5
                ? window.open(`/User/${data_contact?.userNumber}`, '_blank')
                : null
            }
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'User',
                'This is the usernumber of this contact.\nIf this field is empty it means it is not a user.\nClick to open the user site.'
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Created by System"
            name="createdBySystem"
            error={data_contact?.deleted}
            value={data_contact?.createdBySystem}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Contact created by system',
                'This field tells from what system this contact was created.'
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField transparent-inputField"
            control={control}
            setValue={setValue}
            label="Created by User"
            name="createdByUser"
            error={data_contact?.deleted}
            value={data_contact?.createdByUser}
            onClick={() =>
              data_contact?.changedByUser.toString().length === 5
                ? window.open(`/User/${data_contact?.createdByUser}`, '_blank')
                : null
            }
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Contact created by user',
                'This field tells who created the user.\nIf this is a known user in our system you can click on the field to see who created this user.\nIf the field is empty it means it was created by a system-administrator.'
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Created Date"
            name="createdDate"
            error={data_contact?.deleted}
            value={data_contact?.createdDate.split('.')[0].replace('T', ' ')}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Contact created date',
                'This is the date and time of of when this contact was created.'
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Changed by System"
            name="changedBySystem"
            error={data_contact?.deleted}
            value={data_contact?.changedBySystem}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Changed by system',
                'This field tells from what system this Contact was changed.\nIf this field is empty then it means that the contact was changed by the system.'
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputFieldClick"
            control={control}
            setValue={setValue}
            label="Changed by User"
            name="changedByUser"
            error={data_contact?.deleted}
            value={data_contact?.changedByUser}
            onClick={() =>
              data_contact?.changedByUser.toString().length === 5
                ? window.open(`/User/${data_contact?.changedByUser}`, '_blank')
                : null
            }
            onMouseEnter={
              data_contact?.deleted && data_contact?.changedByUser !== 0
                ? (event) =>
                    handleMouseEnter(
                      event,
                      'Deleted by user',
                      'If the contact is not deleted by the system (Usermaster), then this could be showing who might have deleted this contact, click to find out who'
                    )
                : (event) =>
                    handleMouseEnter(
                      event,
                      'Changed by user',
                      'This field show the user who changed this contact.\nIf this is a known user in our system you can click on the field to see whom.\nIf the field is empty it means it was changed by the system.'
                    )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Changed Date"
            name="changedDate"
            error={data_contact?.deleted}
            value={data_contact?.changedDate?.split('.')[0].replace('T', ' ')}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Change date for Contact',
                'This is the date and time of of when this contact was changed.'
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h3
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Phone numbers',
                'These are the phonenumbers that have a relation to the person. \nThe red mark means that the contact no longer has an active relation.'
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            PhoneNumber
          </h3>
        </div>
        {data_contact?.phoneNumbers
          ?.sort((a, b) => {
            // Sort by deleted: false first
            if (a.deleted && !b.deleted) {
              return 1;
            }
            if (!a.deleted && b.deleted) {
              return -1;
            }

            // If both have the same deleted status, sort by number alphabetically
            const phoneNumberA = a.number || '';
            const phoneNumberB = b.number || '';

            if (phoneNumberA < phoneNumberB) {
              return -1;
            }
            if (phoneNumberA > phoneNumberB) {
              return 1;
            }
            return 0;
          })
          .map((phoneNumber, index) => (
            <MDTextField
              key={index}
              className={
                phoneNumber.deleted ? 'inputField transparent' : 'inputField'
              }
              control={control}
              setValue={setValue}
              label={''}
              name={`btp[${index}]`}
              error={phoneNumber.deleted}
              value={phoneNumber.countryCode + ' ' + phoneNumber.number}
            />
          ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h3
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Business Trading Partner',
                'These are the Business Trading Partners that have a relation to the user. \n Only one Business Trading Partner should have an active relation.\nThe red mark means that the user no longer has an active relation'
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            Business Trading Partner
          </h3>
        </div>
        {data_contact?.btps
          ?.sort((a, b) => {
            // Sort by deleted: false first
            if (a.deleted && !b.deleted) {
              return 1;
            }
            if (!a.deleted && b.deleted) {
              return -1;
            }

            // If both have the same deleted status, sort by number alphabetically
            const btpNumberA = a.btp?.btpNumber || '';
            const btpNumberB = b.btp?.btpNumber || '';

            if (btpNumberA < btpNumberB) {
              return -1;
            }
            if (btpNumberA > btpNumberB) {
              return 1;
            }
            return 0;
          })
          .map((btp, index) => (
            <MDTextField
              key={index}
              className={btp.deleted ? 'inputField transparent' : 'inputField'}
              control={control}
              setValue={setValue}
              label={btp.btp?.btpName}
              name={`btp[${index}]`}
              error={btp.deleted}
              value={btp.btp?.btpNumber}
            />
          ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h3
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Person types',
                'These are the person types that have a relation to the person. \nThe red mark means that the contact no longer has an active relation.\nIf there is not active contact relation to this person, then it means this person is not setup to be a contact'
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            Person Type
          </h3>
        </div>
        {data_contact?.personTypes
          ?.sort((a, b) => {
            // Sort by deleted: false first
            if (a.deleted && !b.deleted) {
              return 1;
            }
            if (!a.deleted && b.deleted) {
              return -1;
            }

            // If both have the same deleted status, sort by number alphabetically
            const personTypeNumberA = a.personType?.personTypeNumber || '';
            const personTypeNumberB = b.personType?.personTypeNumber || '';

            if (personTypeNumberA < personTypeNumberB) {
              return -1;
            }
            if (personTypeNumberA > personTypeNumberB) {
              return 1;
            }
            return 0;
          })
          .map((personType, index) => (
            <MDTextField
              key={index}
              className={
                personType.deleted ? 'inputField transparent' : 'inputField'
              }
              control={control}
              setValue={setValue}
              label={''}
              name={`btp[${index}]`}
              error={personType.deleted}
              value={personType.personType?.personTypeName}
            />
          ))}
      </div>
      {/* <div style={{ position: 'fixed', top: '20%', left: '1%' }}>
        {!data_contact?.deleted && (
          <div
            onClick={synchronizeClick}
            className="floating-container"
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Synchronize User',
                'This action will synchronize the user with the other systems and applications. \nThis can maybe solve issues where user-data does not comply with another system.' +
                  (data_contact?.adInviteStatus === 'Accepted'
                    ? '\nIf the user can not login, synchronization might help.'
                    : '')
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            <IconButton color="warning">
              <SyncIcon />
              <label className="floating-label"> Synchronize </label>
            </IconButton>
          </div>
        )}
        {!data_contact?.deleted &&
          data_contact?.adInviteStatus !== 'Accepted' && (
            <div
              onClick={resendInviteClick}
              className="floating-container"
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Resend invite',
                  'This will resend an invitation to the user, the email will be identical to the previous invite they got. \nAny previous invitation will expire. \nIf the user has multiple applications assigned, the user will get an invitation for the application the user last got invited to'
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              <IconButton color="info">
                <EmailIcon />
                <label className="floating-label"> Resend Invite </label>
              </IconButton>
            </div>
          )}
        <div> */}

      {showTooltip && (
        <div className="infobox" style={{ whiteSpace: 'pre-line' }}>
          <h2>{infoboxTitle}</h2>
          <p>{infoboxBody}</p>
        </div>
      )}

      {/* Popup after axios call */}
    </div>
  );
};

export default ContactView;
