import { isNullOrUndefined } from 'utils/QueryStringUtil';

import { SearchForUserAction } from '../_types_/actions/SearchForUserAction';

export const SET_USERCRITERIA_FIRSTNAME = 'SET_USERCRITERIA_FIRSTNAME';
export const SET_USERCRITERIA_LASTNAME = 'SET_USERCRITERIA_LASTNAME';
export const SET_USERCRITERIA_LANGUAGE = 'SET_USERCRITERIA_LANGUAGE';
export const GET_USERCRITERIA_LANGUAGE = 'GET_USERCRITERIA_LANGUAGE';
export const SET_USERCRITERIA_MARKET = 'SET_USERCRITERIA_MARKET';
export const GET_USERCRITERIA_MARKET = 'GET_USERCRITERIA_MARKET';
export const SET_USERCRITERIA_EMAIL = 'SET_USERCRITERIA_EMAIL';
export const SET_USERCRITERIA_ADINVITESTATUS =
  'SET_USERCRITERIA_ADINVITESTATUS';
export const SET_USERCRITERIA_APPLICATION = 'SET_USERCRITERIA_APPLICATION';
export const GET_USERCRITERIA_APPLICATION = 'GET_USERCRITERIA_APPLICATION';
export const GET_USERCRITERIA_BTPS = 'GET_USERCRITERIA_BTPS';
export const SET_USERCRITERIA_BTPS = 'SET_USERCRITERIA_BTPS';
export const SET_USERCRITERIA_BTPNUMBER = 'SET_USERCRITERIA_BTPNUMBER';
export const SET_USERCRITERIA_SUPPLIERNUMBER =
  'SET_USERCRITERIA_SUPPLIERNUMBER';
export const SET_USERCRITERIA_SHIPTONUMBER = 'SET_USERCRITERIA_SHIPTONUMBER';
export const SET_USERCRITERIA_INVOICEACCOUNTNUMBER =
  'SET_USERCRITERIA_INVOICEACCOUNTNUMBER';
export const SET_USERCRITERIA_USERSTYPE = 'SET_USERCRITERIA_USERSTYPE';
export const GET_USERCRITERIA_USERSTYPE = 'GET_USERCRITERIA_USERSTYPE';

export const searchForUserActionInitialState: SearchForUserAction = {
  type: '',
  UserSearchCriteria: {
    firstname: null,
    lastname: null,
    email: null,
    language: null,
    market: null,
    adInviteStatus: 'All',
    application: 'All',
    btpNumber: 0,
    invoiceAccountNumber: 0,
    supplierNumber: 0,
    shipToNumber: 0,
    usersType: 'All',
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SearchForUserReducer = (
  state = searchForUserActionInitialState,
  action: SearchForUserAction,
) => {
  switch (action.type) {
    case SET_USERCRITERIA_FIRSTNAME: {
      let firstnameValue: string | null = '';
      if (action.UserSearchCriteria.firstname === '') {
        firstnameValue = null;
      } else {
        firstnameValue = action.UserSearchCriteria.firstname;
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          firstname: firstnameValue,
        },
      };
    }
    case SET_USERCRITERIA_LASTNAME: {
      let lastnameValue: string | null = '';
      if (action.UserSearchCriteria.lastname === '') {
        lastnameValue = null;
      } else {
        lastnameValue = action.UserSearchCriteria.lastname;
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          lastname: lastnameValue,
        },
      };
    }

    case SET_USERCRITERIA_LANGUAGE: {
      let languageValue: string | null = '';
      if (action.UserSearchCriteria.language === '') {
        languageValue = null;
      } else {
        languageValue = action.UserSearchCriteria.language;
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          language: languageValue,
        },
      };
    }

    case SET_USERCRITERIA_MARKET: {
      let marketValue: string | null = '';
      if (action.UserSearchCriteria.market === '') {
        marketValue = null;
      } else {
        marketValue = action.UserSearchCriteria.market;
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          market: marketValue,
        },
      };
    }

    case SET_USERCRITERIA_ADINVITESTATUS: {
      let adInviteStatusValue: string | null = '';
      if (action.UserSearchCriteria.adInviteStatus === '') {
        adInviteStatusValue = null;
      } else {
        adInviteStatusValue = action.UserSearchCriteria.adInviteStatus;
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          adInviteStatus: adInviteStatusValue,
        },
      };
    }

    case SET_USERCRITERIA_APPLICATION: {
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          application: action.UserSearchCriteria.application,
        },
      };
    }

    case SET_USERCRITERIA_BTPS: {
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          btpNumber: action.UserSearchCriteria.btpNumber,
        },
      };
    }

    case SET_USERCRITERIA_USERSTYPE: {
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          usersType: action.UserSearchCriteria.usersType,
        },
      };
    }

    // case GET_USERCRITERIA_BTPS: {
    //   return {
    //     ...state,
    //     FetchData: {
    //       ...state.FetchData,
    //       allBTPs: action.FetchData.allBTPs,
    //     },
    //   };
    // }

    case SET_USERCRITERIA_EMAIL: {
      let emailValue: string | null = '';
      if (action.UserSearchCriteria.email === '') {
        emailValue = null;
      } else {
        emailValue = action.UserSearchCriteria.email;
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          email: emailValue,
        },
      };
    }

    case SET_USERCRITERIA_BTPNUMBER: {
      let btpNumberValue: number | null = 0;

      if (isNaN(action.UserSearchCriteria.btpNumber)) {
        btpNumberValue = 0;
      } else {
        btpNumberValue = action.UserSearchCriteria.btpNumber;
      }

      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          btpNumber: btpNumberValue,
        },
      };
    }

    case SET_USERCRITERIA_INVOICEACCOUNTNUMBER: {
      let invoiceAccountNumberValue: number | null = 0;
      if (!isNullOrUndefined(action.UserSearchCriteria.invoiceAccountNumber)) {
        if (isNaN(action.UserSearchCriteria.invoiceAccountNumber)) {
          invoiceAccountNumberValue = 0;
        } else {
          invoiceAccountNumberValue =
            action.UserSearchCriteria.invoiceAccountNumber;
        }
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          invoiceAccountNumber: invoiceAccountNumberValue,
        },
      };
    }

    case SET_USERCRITERIA_SHIPTONUMBER: {
      let shipToNumberValue: number | null = 0;
      if (!isNullOrUndefined(action.UserSearchCriteria.shipToNumber)) {
        if (isNaN(action.UserSearchCriteria.shipToNumber)) {
          shipToNumberValue = 0;
        } else {
          shipToNumberValue = action.UserSearchCriteria.shipToNumber;
        }
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          shipToNumber: shipToNumberValue,
        },
      };
    }

    case SET_USERCRITERIA_SUPPLIERNUMBER: {
      let supplierNumberValue: number | null = 0;
      if (!isNullOrUndefined(action.UserSearchCriteria.supplierNumber)) {
        if (isNaN(action.UserSearchCriteria.supplierNumber)) {
          supplierNumberValue = 0;
        } else {
          supplierNumberValue = action.UserSearchCriteria.supplierNumber;
        }
      }
      return {
        ...state,
        UserSearchCriteria: {
          ...state.UserSearchCriteria,
          supplierNumber: supplierNumberValue,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default SearchForUserReducer;
