import { FetchDataAction } from '_types_/actions/FetchDataAction';

export const GET_CRITERIA_LANGUAGE = 'GET_CRITERIA_LANGUAGE';
export const GET_CRITERIA_APPLICATION = 'GET_CRITERIA_APPLICATION';
export const GET_CRITERIA_MARKET = 'GET_CRITERIA_MARKET';

export const FetchDataInitialState: FetchDataAction = {
  type: '',
  FetchData: {
    allApplications: [],
    allLanguages: [],
    allMarkets: [],
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SearchForPersonReducer = (
  state = FetchDataInitialState,
  action: FetchDataAction
) => {
  switch (action.type) {
    case GET_CRITERIA_LANGUAGE: {
      return {
        ...state,
        FetchData: {
          ...state.FetchData,
          allLanguages: action.FetchData.allLanguages,
        },
      };
    }

    case GET_CRITERIA_MARKET: {
      return {
        ...state,
        FetchData: {
          ...state.FetchData,
          allMarkets: action.FetchData.allMarkets,
        },
      };
    }

    case GET_CRITERIA_APPLICATION: {
      return {
        ...state,
        FetchData: {
          ...state.FetchData,
          allApplications: action.FetchData.allApplications,
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default SearchForPersonReducer;
