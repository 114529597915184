import axios, { AxiosPromise } from 'axios';

import { UserAuditsDto } from '_types_/UserAuditsDto_BackEnd';
import { UserInFrontEnd_Dto } from '_types_/UserIn_Dto_BackEnd';
import { UserModelDto_Full, btps } from '_types_/UserModelDto_Full';

import { properties } from '../properties';

const requestOptions = {
  headers: {
    Accept: 'text/plain',
    'Content-Type': 'text/plain',
    'Access-Control-Allow-Origin': '*',
  },
};

const requestOptionsJson = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const getUser = (
  userNumber: string | null,
  bearerToken: string,
): AxiosPromise<UserModelDto_Full> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Get/User',
    method: 'post',
    data: userNumber,
    headers: headers,
    withCredentials: true,
  });
};

const getAvailableRoles = (
  userNumber: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Get/User/AvailableRoles',
    method: 'post',
    data: userNumber,
    headers: headers,
    withCredentials: true,
  });
};

const getAvailableShipTos = (
  userNumber: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Get/User/AvailableShipTos',
    method: 'post',
    data: userNumber,
    headers: headers,
    withCredentials: true,
  });
};

const getPersonBtps = (
  userNumber: string | null,
  bearerToken: string,
): AxiosPromise<btps[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Get/User/Person/Btps',
    method: 'post',
    data: userNumber,
    headers: headers,
    withCredentials: true,
  });
};

const getQueue = (
  userNumber: string | null,
  bearerToken: string,
): AxiosPromise<UserInFrontEnd_Dto> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Get/User/Queue',
    method: 'post',
    data: userNumber,
    headers: headers,
    withCredentials: true,
  });
};

const getUserAudits = (
  userNumber: string | null,
  bearerToken: string,
): AxiosPromise<UserAuditsDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Get/User/Audits',
    method: 'post',
    data: userNumber,
    headers: headers,
    withCredentials: true,
  });
};

const resendInvite = (
  email: string | null,
  sendToMe: boolean,
  sendToMeEmail: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/Invite/Resend',
    method: 'post',
    data: JSON.stringify({
      email: email,
      sendToMe: sendToMe,
      sendToMeEmail: sendToMeEmail,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const adAccessCheck = (
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/AccessCheck',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const loginCheck = (
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/LoginCheck',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const resetUser = (
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/Reset',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const addRole = (
  roleName: string | null,
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/Add/Role',
    method: 'post',
    data: JSON.stringify({
      roleName: roleName,
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const addShipTo = (
  shipToNumber: string | null,
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/Add/ShipTo',
    method: 'post',
    data: JSON.stringify({
      shipToNumber: shipToNumber,
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const removeShipTo = (
  shipToNumber: string | null,
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/Remove/ShipTo',
    method: 'post',
    data: JSON.stringify({
      shipToNumber: shipToNumber,
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const sendOutUser = (
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/SendOut',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const deleteUser = (
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/Delete',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const reActivateUser = (
  userNumber: string | null,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'UserView/Action/User/ReActivate',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

export const userView = {
  getQueue,
  resendInvite,
  adAccessCheck,
  sendOutUser,
  loginCheck,
  resetUser,
  getUser,
  getPersonBtps,
  getAvailableRoles,
  getAvailableShipTos,
  reActivateUser,
  deleteUser,
  addRole,
  addShipTo,
  removeShipTo,
  getUserAudits,
};
