import { ReactElement, useEffect, useState } from 'react';

import FactoryIcon from '@mui/icons-material/Factory';
import PersonIcon from '@mui/icons-material/Person';
import { Button } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';

import Authentication from './Authentication';

const FrontPage = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();

  const dispatch = useDispatch();
  useState('Customer Portal');

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('path');

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/FrontPage');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, dispatch]);

  const handleCustomerSearch = () => {
    // Replace with navigation logic for Customer Search Engine
    window.location.href = '/UserSearchCustomer';
  };

  const handleSupplierSearch = () => {
    // Replace with navigation logic for Supplier Search Engine
    window.location.href = '/UserSearchSupplier';
  };

  return (
    <>
      {bearerToken === '' ? (
        <Authentication />
      ) : (
        <div
          className="main-container"
          style={{ textAlign: 'center', padding: '20px' }}
        >
          <h1 style={{ margin: '20px 0' }}>Welcome!</h1>
          <p>
            Please select one of the following options to proceed to your
            desired search engine:
          </p>
          <div>
            <Button
              variant="contained"
              color="primary"
              className="sendButton"
              style={{
                margin: '10px',
                display: 'inline-block',
                fontSize: '18px', // Bigger text
                padding: '15px 30px', // Increased padding
              }}
              onClick={handleCustomerSearch}
            >
              Customer
              <PersonIcon style={{ marginLeft: 10 }} />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="sendButton"
              style={{
                margin: '10px',
                display: 'inline-block',
                fontSize: '18px', // Bigger text
                padding: '15px 30px', // Increased padding
              }}
              onClick={handleSupplierSearch}
            >
              Supplier
              <FactoryIcon style={{ marginLeft: 10 }} />
            </Button>
          </div>
          <div className="suggestion" style={{ marginTop: '20px' }}>
            <h3>Suggestion:</h3>
            <p>
              If you only manage customers or suppliers, consider creating a
              bookmark for the respective search engine to quickly access it in
              the future.
            </p>
          </div>
        </div>
      )}
      {/* Popup after axios call */}
    </>
  );
};

export default FrontPage;
