import { ReactElement, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
//import Snowfall from 'react-snowfall';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MDTextField from 'UI/MDTextField/MDTextField';
import { getWithExpiry } from 'utils/LocalStorage';

import logo_BESTSELLER from './BESTSELLER_black.png';
import classes from './TopBar.module.scss';

// import { getEnviroment } from '../../utils/Enviroment';

const TopBar = (): ReactElement => {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userNumber, setUserNumber] = useState('');
  const { errors, control, setValue } = useForm();

  useEffect(() => {
    if (auth.isAuthenticated) {
      const key = 'auth';
      const path = getWithExpiry(key);
      navigate(path);
    }
  }, [auth, dispatch, navigate]);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      window.open(`/User/${userNumber}`, '_blank');
    }
  };

  const authLogin = (
    <>
      {window.location.href.toLowerCase().includes('usersearch') ||
      window.location.href.toLowerCase().includes('personsearch') ||
      window.location.href.toLowerCase().includes('btpsearch') ||
      window.location.href.toLowerCase().includes('queue') ||
      window.location.href.toLowerCase().includes('/email') ||
      window.location.href.toLowerCase().includes('/user/') ||
      window.location.href.toLowerCase().includes('/contact/') ||
      window.location.href.toLowerCase().includes('/useroverview') ||
      window.location.href.toLowerCase().includes('/inviteoverview') ||
      window.location.href.toLowerCase().includes('/invite') ||
      window.location.href.toLowerCase().includes('/inviteoverviewsupplier') ||
      window.location.href.toLowerCase().includes('/signinoverview') ||
      window.location.href.toLowerCase().includes('governance') ? (
        auth.isAuthenticated ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'relative',
            }}
          >
            {/* <Snowfall /> */}

            <nav className="navbar">
              <img
                src={logo_BESTSELLER}
                onClick={() => {
                  window.location.href = '/FrontPage';
                }}
                className={classes.imgBestseller}
                alt="logo"
              />
              <ul className="nav-links">
                <li>
                  <Button
                    className={
                      window.location.href.toLowerCase().includes('usersearch')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      // Check the current URL and redirect based on the condition
                      const currentUrl = window.location.href.toLowerCase();

                      if (currentUrl.includes('supplier')) {
                        window.location.href = '/usersearchSupplier';
                      } else if (currentUrl.includes('customer')) {
                        window.location.href = '/usersearchCustomer';
                      } else {
                        window.location.href = '/UserSearch'; // Default fallback
                      }
                    }}
                  >
                    User Search
                  </Button>
                </li>
                <li>
                  <Button
                    className={
                      window.location.href.toLowerCase().includes('governance')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      window.location.href = '/Governance';
                    }}
                  >
                    Governance
                  </Button>
                </li>
                <li>
                  <Button
                    className={
                      window.location.href
                        .toLowerCase()
                        .includes('personsearch')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      window.location.href = '/PersonSearch';
                    }}
                  >
                    Person Search
                  </Button>
                </li>
                <li>
                  <Button
                    className={
                      window.location.href.toLowerCase().includes('btpsearch')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      window.location.href = '/BtpSearch';
                    }}
                  >
                    BTP Search
                  </Button>
                </li>
                <li>
                  <Button
                    className={
                      window.location.href.toLowerCase().includes('email')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      window.location.href = '/Email';
                    }}
                  >
                    Emails
                  </Button>
                </li>

                <li>
                  <Button
                    className={
                      window.location.href
                        .toLowerCase()
                        .includes('inviteoverview') &&
                      !window.location.href
                        .toLowerCase()
                        .includes('inviteoverviewsupplier')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      window.location.href = '/InviteOverview';
                    }}
                  >
                    Customer Overview
                  </Button>
                </li>
                <li>
                  <Button
                    className={
                      window.location.href
                        .toLowerCase()
                        .includes('inviteoverviewsupplier')
                        ? 'nav-linksOn'
                        : ''
                    }
                    onClick={() => {
                      window.location.href = '/InviteOverviewSupplier';
                    }}
                  >
                    Supplier Overview
                  </Button>
                </li>
                <li
                  style={{
                    width: '150px',
                    marginTop: '18px',
                    marginLeft: '18px',
                  }}
                >
                  {' '}
                  <MDTextField
                    className="inputField"
                    control={control}
                    setValue={setValue}
                    label="Open user"
                    name="userNumber"
                    error={errors.userNumber}
                    value={userNumber}
                    onChangeSetValue={(value: string): void => {
                      setUserNumber(value);
                    }}
                    onKeyDown={handleKeyPress}
                    size="small"
                  />
                </li>
              </ul>
              <button
                title="Logout"
                className={classes.authLogin}
                onClick={() => {
                  void auth.removeUser();
                  window.location.reload();
                }}
              >
                ONLINE
              </button>
            </nav>
          </div>
        ) : (
          <div>
            <div>
              <nav className="navbar">
                <img
                  src={logo_BESTSELLER}
                  onClick={() => {
                    window.location.reload();
                  }}
                  className={classes.imgBestseller}
                  alt="logo"
                />
                <button
                  title="Login"
                  className={classes.authLogout}
                  onClick={() => {
                    auth.signinRedirect();
                  }}
                >
                  Log in here
                </button>
              </nav>
            </div>
          </div>
        )
      ) : undefined}
    </>
  );

  return <header className={classes.topBar}>{authLogin}</header>;
};
export default TopBar;
