import { ReactElement } from 'react';

const Authentication = (): ReactElement => {
  //const [seconds, setSeconds] = useState(10);

  // useEffect(() => {
  //   if (seconds > 0) {
  //     const timer = setInterval(() => {
  //       setSeconds((prevSeconds) => prevSeconds - 1);
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [seconds]);

  return (
    <div className="main-container">
      <p className="moveElementToCenter">
        <strong>
          This site requires authentication, please contact It-support if login
          fails. Login in upper right corner
        </strong>
      </p>

      {/* <div className="moveElementToCenter">
        {seconds === 0 ? (
          <p>Logging in now...</p>
        ) : (
          <p> Attempt to automatic login in {seconds}</p>
        )}
      </div> */}
    </div>
  );
};

export default Authentication;
