import { createRef, ReactElement, useEffect, useState } from 'react';

import { Search } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';

import { usermasterAPI } from '_services_/usermasterAPI';
import { SearchForUserAction } from '_types_/actions/SearchForUserAction';
import { UserModelDto } from '_types_/UserModelDto';
import UserSearchComponent from 'components/UserSearchComponent';
import { getWithExpiry, setWithExpiry } from 'utils/LocalStorage';
import {
  getAllApplications,
  //getAllBTPs,
  getAllLanguages,
  getAllMarkets,
} from 'utils/UserSearchUtil';

import Authentication from './Authentication';

const UserSearch = (): ReactElement => {
  const [bearerToken, setBearerToken] = useState('');
  const auth = useAuth();
  const [data, setData] = useState<UserModelDto[]>([]);
  const [count, setCount] = useState(1);
  const [rowsPerPage] = useState(20);
  // const auth = useAuth();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    search: true,
    filterType: 'multiselect',
    responsive: 'vertical',
    count: count,
    selectableRows: 'none',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [15, 20, 100],
    onCellClick(colData, cellMeta) {
      if (cellMeta.colIndex === 12) {
        window.open('/User/' + data[cellMeta.dataIndex].userNumber, '_blank');
      }
    },
    sort: false,
    resizableColumns: false,
    onFilterChange: (
      columnChanged,
      filterList,
      type,
      changedColumnChanged,
      displayData,
    ) => {
      setCount(displayData?.length);
    },
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const bearerToken_temp = auth.user?.id_token!;
      setBearerToken(bearerToken_temp);
      getWithExpiry('auth');

      getAllApplications(setIsLoading, dispatch);
      //getAllBTPs(setIsLoading, dispatch);
      getAllLanguages(setIsLoading, dispatch);
      getAllMarkets(setIsLoading, dispatch);

      localStorage.removeItem('auth');
    } else {
      setWithExpiry('auth', '/UserSearch');
    }
  }, [auth, dispatch]);

  const [columns] = useState([
    {
      name: 'userNumber',
      label: 'UserNumber',
      options: {
        sort: false,
        customBodyRender: (value: string) => {
          return value;
        },
      },
    },
    {
      name: 'person',
      label: 'Firstname',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value.firstname;
        },
      },
    },
    {
      name: 'person',
      label: 'Lastname',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value.lastName;
        },
      },
    },
    {
      name: 'person',
      label: 'Language',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value.language;
        },
      },
    },
    {
      name: 'person',
      label: 'Email',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value.email;
        },
      },
    },
    {
      name: 'btps',
      label: 'BTP',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          let stringArray = '';
          if (value !== null) {
            if (value.length !== 0) {
              value.forEach((btps: any) => {
                if (btps.deleted === false) {
                  if (stringArray !== '') {
                    stringArray += ', ';
                  }
                  if (btps.btp !== null) {
                    stringArray += btps.btp.btpNumber;
                  }
                }
              });
            }
          }
          return stringArray;
        },
      },
    },
    {
      name: 'invoiceAccounts',
      label: 'Invoice Accounts',
      viewColumns: false,
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          let stringArray = '';
          if (value !== null) {
            if (value.length !== 0) {
              value.forEach((invoiceAccounts: any) => {
                if (invoiceAccounts.deleted === false) {
                  if (stringArray !== '') {
                    stringArray += ', ';
                  }
                  stringArray +=
                    invoiceAccounts.invoiceAccount.invoiceAccountNumber;
                }
              });
            }
          }
          return stringArray;
        },
      },
    },
    {
      name: 'shipTos',
      label: 'Ship Tos',
      viewColumns: false,
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          let stringArray = '';
          if (value !== null) {
            if (value.length !== 0) {
              value.forEach((shipTos: any) => {
                if (shipTos.deleted === false) {
                  if (stringArray !== '') {
                    stringArray += ', ';
                  }
                  stringArray += shipTos.shipTo.shipToNumber;
                }
              });
            }
          }
          return stringArray;
        },
      },
    },
    {
      name: 'suppliers',
      label: 'Suppliers',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          let stringArray = '';
          if (value !== null) {
            if (value.length !== 0) {
              value.forEach((suppliers: any) => {
                if (suppliers.deleted === false) {
                  if (stringArray !== '') {
                    stringArray += ', ';
                  }
                  stringArray += suppliers.supplier.supplierNumber;
                }
              });
            }
          }
          return stringArray;
        },
      },
    },
    {
      name: 'adInviteStatus',
      label: 'Invite Status',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value;
        },
      },
    },
    {
      name: 'inviteSend',
      label: 'Invite send',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: string) => {
          return value === null ? value : value.split('T')[0];
        },
      },
    },
    {
      name: 'deleted',
      label: 'Deleted',
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value ? 'Yes' : 'No';
        },
      },
    },
    {
      name: 'open',
      label: 'Open',
      style: {
        cursor: 'pointer',
      },
      options: {
        sort: false,
        customBodyRender: () => {
          return (
            <div style={{ cursor: 'pointer' }}>
              <SettingsIcon />
            </div>
          );
        },
      },
    },
  ]);

  const { trigger, formState, errors, control, handleSubmit, setValue } =
    useForm();

  const searchForUser = useSelector(
    (state: { SearchForUser: SearchForUserAction }) => state.SearchForUser,
  );

  const form = createRef<ValidatorForm>();

  const handleUserCritaria = (): void => {
    if (form.current && !form.current.isFormValid(false)) {
      return;
    } else {
      let criterias = searchForUser.UserSearchCriteria;

      if (criterias.adInviteStatus === 'All') {
        criterias = { ...criterias, adInviteStatus: null };
      }

      if (criterias.language === 'All') {
        criterias = { ...criterias, language: null };
      }

      if (criterias.market === 'All') {
        criterias = { ...criterias, market: null };
      }

      if (criterias.application === 'All') {
        criterias = { ...criterias, application: null };
      }
      setIsLoading(true);
      usermasterAPI
        .getSearchedUsers(criterias, bearerToken)
        .then((response: AxiosResponse<UserModelDto[]>): void => {
          setData(response.data);
          setCount(response.data.length);
        })
        .catch((error: AxiosError): void => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return bearerToken === '' ? (
    <Authentication />
  ) : (
    <div>
      <form onSubmit={handleSubmit(handleUserCritaria)}>
        <div className="main-container">
          <UserSearchComponent
            control={control}
            trigger={trigger}
            errors={errors}
            formState={formState}
            setValue={setValue}
            isLoading={isLoading}
          />
          <Button
            variant="contained"
            color="primary"
            className="sendButton"
            style={{ float: 'right' }}
            type="submit"
          >
            Search
            <Search style={{ marginLeft: 10 }} />
          </Button>
        </div>
      </form>
      <div className="search">
        <MUIDataTable
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '30px 0px',
              }}
            >
              <h2 style={{ margin: '0' }}>User overview</h2>
            </div>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default UserSearch;
