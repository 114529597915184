import React, { useEffect, useRef } from 'react';

import {
  Button,
  ClickAwayListener,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  Controller,
  Validate,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';

import { usePaperStyles } from '../../_styling_/usePaperStyles';

interface PropTypes {
  name: string;
  label: string;
  value: any;
  error: any;
  rules?:
    | Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
      }>
    | undefined;
  control: any;
  disabled?: boolean;
  onChangeSetValue?: (value: any) => void;
  className?: string;
  prefixText?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  inputProps?: any;
  InputLabelProps?: any;
  setValue?: any;
  onClick?: (value: any) => void;
  onKeyDown?: (value: any) => void;
  onMouseEnter?: (value: any) => void;
  onMouseLeave?: (value: any) => void;
  size?: any;
  onDragStart?: React.DragEventHandler<HTMLDivElement>;
  draggable?: boolean;
}

const MDTextField: React.FC<PropTypes> = (props: PropTypes) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const classes = usePaperStyles();

  const { name, value, setValue } = props;

  const val = value || '';

  useEffect(() => {
    if (setValue !== undefined) {
      setValue(name, value);
    }
  }, [name, value, setValue]);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          className="tooltip"
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied to Clipboard"
          style={{
            marginTop: '80px',
            marginLeft: '95%',
            position: 'fixed',
            top: '0px',
          }}
        >
          {/* Just becuase it need to have some content */}
          <Button onClick={handleTooltipOpen} />
        </Tooltip>
      </ClickAwayListener>

      <Controller
        control={props.control}
        render={({ onChange }) => (
          <TextField
            multiline={false}
            rows={props.rows}
            label={props.label}
            inputRef={inputRef}
            inputProps={props.inputProps}
            InputLabelProps={props.InputLabelProps}
            type={props.type}
            variant="outlined"
            helperText={props.error && props.error.message}
            error={props.error ? true : false}
            onChange={(event) => {
              (props.onChangeSetValue ?? (() => {}))(event.target.value);
              onChange(event.target.value);
            }}
            InputProps={
              props.prefixText
                ? {
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginRight: '-3px' }}
                      >
                        {props.prefixText}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            className={
              props.className ? props.className : classes.TextValidator
            }
            value={val}
            disabled={props.disabled ?? false}
            onDoubleClick={() => {
              navigator.clipboard.writeText(val);
              handleTooltipOpen();
            }}
            onClick={props.onClick}
            onKeyDown={props.onKeyDown}
            size={props.size ?? 'medium'}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onDragStart={props.onDragStart} // Add drag event handler
            draggable={props.draggable} // Add draggable attribute
          />
        )}
        name={name}
        rules={props.rules}
        defaultValue={val}
        onFocus={() => {
          inputRef.current?.focus();
        }}
      />
    </>
  );
};

export default MDTextField;
