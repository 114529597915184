import axios, { AxiosPromise } from 'axios';

import InviteOverviewDto from '_types_/InviteOverviewDto';
import SignInHistoryDto from '_types_/SignInHistoryDto';
import { UserOverviewCriteriaModelDto } from '_types_/UserOverviewCriteriaModelDto';

import { properties } from '../properties';

const requestOptionsJson = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const getUserOverview = (
  criteria: UserOverviewCriteriaModelDto | null,
  bearerToken: string
): AxiosPromise<Date[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };
  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };
  return axios({
    url: properties.serviceApiURL + 'Graph/GetUserOverview',
    method: 'post',
    data: JSON.stringify(criteria),
    headers: headers,
    withCredentials: true,
  });
};

const getInviteOverview = (
  bearerToken: string
): AxiosPromise<InviteOverviewDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };
  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };
  return axios({
    url: properties.serviceApiURL + 'Graph/GetInviteOverview',
    method: 'post',
    headers: headers,
    withCredentials: true,
  });
};

const getSignInOverview = (
  bearerToken: string
): AxiosPromise<SignInHistoryDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };
  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };
  return axios({
    url: properties.serviceApiURL + 'Graph/GetSignInOverview',
    method: 'post',
    headers: headers,
    withCredentials: true,
  });
};

const getInviteOverviewSupplier = (
  bearerToken: string
): AxiosPromise<InviteOverviewDto[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };
  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };
  return axios({
    url: properties.serviceApiURL + 'Graph/GetInviteOverviewSupplier',
    method: 'post',
    headers: headers,
    withCredentials: true,
  });
};

export const graphService = {
  getUserOverview,
  getInviteOverview,
  getInviteOverviewSupplier,
  getSignInOverview,
};
