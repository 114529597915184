import axios, { AxiosPromise } from 'axios';

import { Governance_DatErrorClass } from '_types_/GovernanceDto';
import { Queue_Frontend } from '_types_/QueueOutModelDto';

import { properties } from '../properties';

const requestOptions = {
  headers: {
    Accept: 'text/plain',
    'Content-Type': 'text/plain',
    'Access-Control-Allow-Origin': '*',
  },
};

const requestOptionsJson = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

const inviteUser = (
  userNumber: string | null,
  email: string | null,
  firstname: string | null,
  lastname: string | null,
  number: string | null,
  role: string | null,
  requestEmail: string | null,
  bearerToken: string,
): AxiosPromise<string[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'User/InviteUser',
    method: 'post',
    data: JSON.stringify({
      userNumber: userNumber,
      email: email,
      firstname: firstname,
      lastname: lastname,
      number: number,
      role: role,
      requestEmail: requestEmail,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const getQueueMessage = (
  dataType: string,
  identifier: number,
  bearerToken: string,
): AxiosPromise<Queue_Frontend> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'BasicFrontend/QueueMessage',
    method: 'post',
    data: JSON.stringify({
      dataType: dataType,
      identifier: identifier,
    }),
    headers: headers,
    withCredentials: true,
  });
};

const getGovernance = (
  bearerToken: string,
): AxiosPromise<Governance_DatErrorClass[]> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };
  const headers = {
    ...requestOptions.headers,
    ...authTag,
  };
  return axios({
    url: properties.serviceApiURL + 'BasicFrontend/Get/Governance',
    method: 'get',
    headers: headers,
    withCredentials: true,
  });
};

const sendOutQueue = (
  dataType: string,
  application: string,
  identifier: number,
  active: boolean,
  confirmed: boolean,
  count: number,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'BasicFrontEnd/SendOutQueue',
    method: 'post',
    data: JSON.stringify({
      dataType: dataType,
      application: application,
      identifier: identifier,
      active: active,
      confirmed: confirmed,
      count: count,
      requestEmail: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

const sendTestEmail = (
  application: string,
  filename: string,
  email: string | null,
  bearerToken: string,
): AxiosPromise<string> => {
  const authTag = {
    Authorization: `Bearer ` + bearerToken,
  };

  const headers = {
    ...requestOptionsJson.headers,
    ...authTag,
  };

  return axios({
    url: properties.serviceApiURL + 'BasicFrontEnd/SendTestEmail',
    method: 'post',
    data: JSON.stringify({
      application: application,
      filename: filename,
      email: email,
    }),
    headers: headers,
    responseType: 'text',
    withCredentials: true,
  });
};

export const frontendService = {
  getGovernance,
  getQueueMessage,
  inviteUser,
  sendOutQueue,
  sendTestEmail,
};
