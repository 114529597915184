import { ReactElement } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AcceptTPAUser from 'AcceptTPAUser/AcceptTPAUser';
import AcceptUser from 'AcceptUser/AcceptUser';
import BtpSearch from 'components/BtpSearch';
import ContactView from 'components/ContactView';
import Email from 'components/Email';
import FrontPage from 'components/FrontPage';
import Governance from 'components/Governance';
import Invite from 'components/Invite';
import InviteOverview from 'components/InviteOverview';
import InviteOverviewSupplier from 'components/InviteOverviewSupplier';
import PersonSearch from 'components/PersonSearch';
import Queue from 'components/Queue';
import QueueHandler from 'components/QueueHandler';
import SignInOverview from 'components/SignInOverview';
import UserOverview from 'components/UserOverview';
import UserSearch from 'components/UserSearch';
import UserSearchCustomer from 'components/UserSearchCustomer';
import UserSearchSupplier from 'components/UserSearchSupplier';
import UserView from 'components/UserView';
import PageNotAvailable from 'PageNotAvailable/PageNotAvailable';
import TopBar from 'UI/TopBar/TopBar';

const App = (): ReactElement => {
  return (
    <BrowserRouter>
      <TopBar />
      <div className="min-margin-container">
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/AcceptUser/:Id" element={<AcceptUser />} />
          <Route path="/AcceptTPAUser/:Id" element={<AcceptTPAUser />} />
          <Route path="/FrontPage" element={<FrontPage />} />
          <Route path="/UserSearch" element={<FrontPage />} />
          <Route path="/UserSearchAll" element={<UserSearch />} />
          <Route path="/UserSearchCustomer" element={<UserSearchCustomer />} />
          <Route path="/UserSearchSupplier" element={<UserSearchSupplier />} />
          <Route path="/PersonSearch" element={<PersonSearch />} />
          <Route path="/BtpSearch" element={<BtpSearch />} />
          <Route path="/User/:Id" element={<UserView />} />
          <Route path="/Contact/:Id" element={<ContactView />} />
          <Route path="/Governance" element={<Governance />} />
          <Route path="/UserOverview" element={<UserOverview />} />
          <Route path="/InviteOverview" element={<InviteOverview />} />
          <Route path="/SignInOverview" element={<SignInOverview />} />
          <Route path="/Queue" element={<Queue />} />
          <Route path="/Email" element={<Email />} />
          <Route path="/QueueHandler" element={<QueueHandler />} />
          <Route path="/Invite" element={<Invite />} />
          <Route
            path="/InviteOverviewSupplier"
            element={<InviteOverviewSupplier />}
          />
          <Route path="*" element={<PageNotAvailable />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
