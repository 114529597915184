import { ReactElement } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { CUSTOMER_NUMBER, REQUIRED_FIELD } from '_constants_/constants';
import {
  SET_PERSONCRITERIA_FIRSTNAME,
  SET_PERSONCRITERIA_LASTNAME,
  SET_PERSONCRITERIA_MARKET,
  SET_PERSONCRITERIA_EMAIL,
  SET_PERSONCRITERIA_BTPNUMBER,
  SET_PERSONCRITERIA_APPLICATION,
} from '_reducers_/SearchForPersonReducer';
import { FetchDataAction } from '_types_/actions/FetchDataAction';
import { SearchForPersonAction } from '_types_/actions/SearchForPersonAction';
import MDDropDown from 'UI/MDDropDown/MDDropDown';
import MDEmailField from 'UI/MDEmailField/MDEmailField';
import MDTextField from 'UI/MDTextField/MDTextField';

interface PropType {
  control: any;
  errors: any;
  trigger: any;
  formState: any;
  setValue: any;
  isLoading: boolean;
}

const PersonSearchComponent = (props: PropType): ReactElement<PropType> => {
  const { control, errors, setValue, isLoading } = props;

  const searchForPerson = useSelector(
    (state: { SearchForPerson: SearchForPersonAction }) => state.SearchForPerson
  );

  const fetchData = useSelector(
    (state: { FetchData: FetchDataAction }) => state.FetchData
  );

  const dispatch = useDispatch();

  return (
    <>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>

      <div className="inputField-root">
        <MDTextField
          className="inputField"
          control={control}
          setValue={setValue}
          label="First name"
          name="firstname"
          error={errors.firstname}
          value={searchForPerson.PersonSearchCriteria.firstname}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_PERSONCRITERIA_FIRSTNAME,
              PersonSearchCriteria: { firstname: e },
            });
          }}
          rules={{
            required: { message: REQUIRED_FIELD, value: false },
          }}
        />
        <MDTextField
          className="inputField"
          control={control}
          setValue={setValue}
          label="Lastname"
          name="lastname"
          error={errors.lastname}
          value={searchForPerson.PersonSearchCriteria.lastname}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_PERSONCRITERIA_LASTNAME,
              PersonSearchCriteria: { lastname: e },
            });
          }}
          rules={{
            required: { message: REQUIRED_FIELD, value: false },
          }}
        />
      </div>

      <div className="inputField-root">
        <MDDropDown
          control={control}
          className="inputField"
          label="Market"
          name="market"
          error={errors.market}
          value={searchForPerson.PersonSearchCriteria.market}
          items={['All', ...fetchData.FetchData.allMarkets]}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_PERSONCRITERIA_MARKET,
              PersonSearchCriteria: { market: e },
            });
          }}
        />
      </div>

      <MDEmailField
        control={control}
        setValue={setValue}
        emailLabel="Email"
        emailName="email"
        emailError={errors.email}
        emailValue={searchForPerson.PersonSearchCriteria.email}
        emailOnChange={(e: string): void => {
          dispatch({
            type: SET_PERSONCRITERIA_EMAIL,
            PersonSearchCriteria: { email: e },
          });
        }}
      />
      <div className="inputField-root">
        <MDDropDown
          control={control}
          className="inputField"
          label="Application"
          name="application"
          error={errors.application}
          value={searchForPerson.PersonSearchCriteria.application}
          items={[
            'None',
            ...fetchData.FetchData.allApplications.filter(
              (app) => app !== 'BESTSELLER Login'
            ),
          ]}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_PERSONCRITERIA_APPLICATION,
              PersonSearchCriteria: { application: e },
            });
          }}
        />
      </div>
      <div className="inputField-root">
        <MDTextField
          control={control}
          setValue={setValue}
          className="inputField"
          label="Business Trading Partner Number"
          name="btpNumber"
          error={errors.btpNumber}
          value={searchForPerson.PersonSearchCriteria.btpNumber}
          onChangeSetValue={(e: string): void => {
            dispatch({
              type: SET_PERSONCRITERIA_BTPNUMBER,
              PersonSearchCriteria: { btpNumber: Number(e) },
            });
          }}
          rules={{
            required: { message: REQUIRED_FIELD, value: false },
            pattern: {
              value: CUSTOMER_NUMBER,
              message: 'Invalid number',
            },
          }}
        />
      </div>
    </>
  );
};

export default PersonSearchComponent;
