import { ReactElement, useEffect, useState } from 'react';

import { Dialog, LinearProgress } from '@mui/material';
import { AxiosError } from 'axios';

import { userView } from '_services_/userViewService';
import { UserModelDto_Full, btps } from '_types_/UserModelDto_Full';
import MDConfirmDialog from 'UI/MDConfirmDialog/MDConfirmDialog';
import MDEmailField from 'UI/MDEmailField/MDEmailField';
import MDTextField from 'UI/MDTextField/MDTextField';

interface PropType {
  control: any;
  errors: any;
  trigger: any;
  formState: any;
  setValue: any;
  data: UserModelDto_Full | undefined;
  btpPersonData: btps[] | undefined;
  differentBtp: boolean;
  multipleRolesInApplication: string | null;
  userTooManyBtps: boolean;
  availableRoles: string[];
  availableShipTos: string[];
  personTooManyBtps: boolean;
  handleMouseEnter: any;
  handleMouseLeave: any;
  setBlockTooltip: any;
  blockTooltip: boolean;
  auth: any;
  bearerToken: string;
}

const UserViewComponent = (props: PropType): ReactElement<PropType> => {
  const {
    control,
    setValue,
    data,
    btpPersonData,
    differentBtp,
    multipleRolesInApplication,
    userTooManyBtps,
    availableRoles,
    availableShipTos,
    personTooManyBtps,
    handleMouseEnter,
    handleMouseLeave,
    setBlockTooltip,
    blockTooltip,
    auth,
    bearerToken,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirmAddRole = (roleName: any) => {
    if (!data?.deleted) {
      const confirmed = window.confirm(
        'Are you sure you want to add this role to the user?',
      );

      if (confirmed) {
        AddRoleToUser(roleName);
      }
    }
  };

  const handleConfirmAddShipTo = (shipToNumber: any) => {
    if (!data?.deleted) {
      const confirmed = window.confirm(
        'Are you sure you want to add this Ship To to the user?',
      );

      if (confirmed) {
        AddShipToToUser(shipToNumber.toString());
      }
    }
  };

  const handleConfirmRemoveShipTo = (shipToNumber: any) => {
    if (!data?.deleted) {
      const confirmed = window.confirm(
        'Are you sure you want to remove this Ship To to the user?',
      );

      if (confirmed) {
        RemoveShipToOnUser(shipToNumber.toString());
      }
    }
  };

  const AddRoleToUser = async (roleName: any) => {
    setIsLoading(true);
    userView
      .addRole(
        roleName,
        data?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage(
          'Requested to add role to user, please refresh the site to see changes!',
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const AddShipToToUser = async (shipToNumber: any) => {
    setIsLoading(true);
    userView
      .addShipTo(
        shipToNumber,
        data?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage(
          'Requested to add Ship To to user, please refresh the site to see changes!',
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  const RemoveShipToOnUser = async (shipToNumber: any) => {
    setIsLoading(true);
    userView
      .removeShipTo(
        shipToNumber,
        data?.userNumber.toString() ?? null,
        auth.user?.profile.email === undefined
          ? null
          : auth.user?.profile.email,
        bearerToken,
      )
      .then(() => {
        setResponseMessage(
          'Requested to remove Ship To on user, please refresh the site to see changes!',
        );
        handleOpen();
      })
      .catch((error: AxiosError): void => {
        setResponseMessage(error.response?.data as string);
        handleOpen();
      })
      .finally((): void => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (data?.deleted) {
      setResponseMessage(
        'This user is deleted and cannot be edited, if you wish to restore the user to the previous state please use the reactivation button on the left',
      );
      handleOpen();
    }
  }, [data?.deleted]);

  return (
    <div>
      <Dialog open={isLoading}>
        <LinearProgress style={{ width: '200px' }} />
      </Dialog>
      <MDConfirmDialog
        message={responseMessage}
        open={open}
        onClose={handleClose}
        disableActions={true}
      />
      <div className={'mainViewUser-container'}>
        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="First name"
            name="firstname"
            error={data?.person.deleted}
            value={data?.person.firstname}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Lastname"
            name="lastname"
            error={data?.person.deleted}
            value={data?.person.lastName}
          />
        </div>
        <MDEmailField
          control={control}
          setValue={setValue}
          emailLabel="Email"
          emailName="email"
          emailError={data?.person.deleted}
          emailValue={data?.person.email}
        />
        <MDTextField
          control={control}
          setValue={setValue}
          label="Language"
          name="language"
          error={data?.person.deleted}
          value={data?.person.language}
        />

        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="User Number"
            name="userNumber"
            error={data?.deleted}
            value={data?.userNumber}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Person Number"
            name="personNumber"
            error={data?.person.deleted}
            value={data?.person.personNumber}
            onClick={() =>
              window.open(`/Contact/${data?.person.personNumber}`, '_blank')
            }
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Person Number',
                'This is the person number for this user. \nYou can click on the field to see more personal data (also related to Contacts).',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>

        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="User Type"
            name="adUserType"
            error={data?.deleted}
            value={data?.adUserType}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'User Type',
                'This is the user type from Microsofts perspective and is either a Member or a Guest. \nA member is interally known by Bestseller and will most likely have internal Bestseller access too, and a Guest is an external partner or customer.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="AdGuid"
            name="adguid"
            error={data?.deleted}
            value={data?.adGuid}
            onMouseEnter={(event) => {
              const adGuid = data?.adGuid || 'default-guid'; // Use a default value if data?.adGuid is undefined
              const tooltipText = `This is Microsoft's Guid, to identify the user in Active Directory. \nSee more about the user here (restricted access): <a href="https://portal.azure.com/#view/Microsoft_AAD_UsersAndTenants/UserProfileMenuBlade/~/overview/userId/${adGuid}" target="_blank" rel="noopener noreferrer">Azure Portal</a>`;
              handleMouseEnter(event, 'AdGuid', tooltipText);
            }}
            onMouseLeave={handleMouseLeave}
          />
        </div>

        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Invite Send"
            name="inviteSend"
            error={data?.deleted}
            value={data?.inviteSend?.split('.')[0].replace('T', ' ')}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Invite Send',
                'This is the date and time of when this user last time got an invitation \nThis field will change if you resend an invitation.\nYou can resend an invite with the blue email symbol found to the left of the window.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Mailjet Status"
            name="mailjetStatus"
            error={data?.deleted}
            value={data?.mailjetStatus}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Mailjet',
                'Status of the mail in Mailjet. Read more about the different statuses here: <a href="https://documentation.mailjet.com/hc/en-us/articles/360048398994-Email-statuses-all-metrics-explained" target="_blank" rel="noopener noreferrer">Mailjet Statuses</a> \n (Freeze this box by left-click on the "Mailjet Status"-field)',
              )
            }
            onClick={() =>
              setBlockTooltip(blockTooltip === false ? true : false)
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Invite Status"
            name="adInviteStatus"
            error={data?.deleted}
            value={data?.adInviteStatus}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Invite Status',
                'This is the status seen from Microsofts perspective. \nIf the user did not accept the invitation the Microsoft policy the status will be "Invited", but if the user accepted then the status will be "Accepted" and the user can now login directly into the users application(s).\nYou can resend an email-invite with the blue email symbol found to the left of the window.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Accepted Date"
            name="acceptedDate"
            error={data?.deleted}
            value={data?.adInviteAcceptedDate?.split('.')[0].replace('T', ' ')}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'User accepted the invitation',
                'This is the date and time of when the user accepted the invitation.\nThis date is seen from Microsofts perspective of when the user accepted their terms and conditions, and will only be set one time; when the user accepts an invitation for the first time.\n',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Created by System"
            name="createdBySystem"
            error={data?.deleted}
            value={data?.createdBySystem}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'User created by system',
                'This field tells from what system this user was created.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField transparent-inputField"
            control={control}
            setValue={setValue}
            label="Created by User"
            name="createdByUser"
            error={data?.deleted}
            value={data?.createdByUser}
            onClick={() =>
              data?.changedByUser.toString().length === 5
                ? window.open(`/User/${data?.createdByUser}`, '_blank')
                : null
            }
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'User created by user',
                'This field tells who created the user.\nIf this is a known user in our system you can click on the field to see who created this user.\nIf the field is empty it means it was created by a system-administrator.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Created Date"
            name="createdDate"
            error={data?.deleted}
            value={data?.createdDate?.split('.')[0].replace('T', ' ')}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'User created date',
                'This is the date and time of of when this user was created.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div className="inputField-root">
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Changed by System"
            name="changedBySystem"
            error={data?.deleted}
            value={data?.changedBySystem}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Changed by system',
                'This field tells from what system this user was changed.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputFieldClick"
            control={control}
            setValue={setValue}
            label="Changed by User"
            name="changedByUser"
            error={data?.deleted}
            value={data?.changedByUser}
            onClick={() =>
              data?.changedByUser.toString().length === 5
                ? window.open(`/User/${data?.changedByUser}`, '_blank')
                : null
            }
            onMouseEnter={
              data?.deleted && data?.changedByUser !== 0
                ? (event) =>
                    handleMouseEnter(
                      event,
                      'Deleted by user',
                      'If the user is not deleted by the system (Usermaster), then this could be showing who might have deleted this user, click to find out who',
                    )
                : (event) =>
                    handleMouseEnter(
                      event,
                      'Changed by user',
                      'This field show the user who changed this user.\nIf this is a known user in our system you can click on the field to see whom.\nIf the field is empty it could mean that it was changed by the system.',
                    )
            }
            onMouseLeave={handleMouseLeave}
          />
          <MDTextField
            className="inputField"
            control={control}
            setValue={setValue}
            label="Changed Date"
            name="changedDate"
            error={data?.deleted}
            value={data?.changedDate?.split('.')[0].replace('T', ' ')}
            onMouseEnter={(event) =>
              handleMouseEnter(
                event,
                'Change date for User',
                'This is the date and time of of when this user was changed.',
              )
            }
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
      <div className="secondViewUser-container">
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Applications',
                  'These are the applications that have a relation to the user.\nBESTSELLER Login is an alias for the login-system being used called "Active Directory" (AD) managed by Microsoft.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Applications
            </h3>
          </div>
          {data?.applications
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((application, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                label={''}
                name={`application[${index}]`}
                error={application.deleted}
                value={application.application?.applicationName}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              display:
                data?.tpaApplications?.length !== undefined &&
                data?.tpaApplications?.length > 0
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Applications - Extra access',
                  'These are the applications that have a relation to the user, but as extra access granted by other companies.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Applications - Extra access
            </h3>
          </div>
          {data?.tpaApplications
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((application, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                label={''}
                name={`application[${index}]`}
                error={application.deleted}
                value={application.application?.applicationName}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Roles',
                  'These are the roles that have a relation to the user. \n A role should correspond to an application the user also has a relation to.\nThe red mark means that the user no longer has an active relation \nThe red- and grey-transparent fields enables you to add a role to the user by clicking the field.',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Roles
            </h3>
          </div>
          {data?.roles
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((role, index) => (
              <MDTextField
                key={index}
                className={`inputField ${
                  role.applicationRole?.application?.applicationNumber ===
                  multipleRolesInApplication
                    ? 'flash'
                    : ''
                } ${data?.deleted ? 'transparent' : ''}`}
                control={control}
                setValue={setValue}
                onClick={() =>
                  role.deleted
                    ? handleConfirmAddRole(role.applicationRole?.roleName)
                    : null
                }
                label={
                  role.applicationRole?.application?.applicationNumber ===
                  multipleRolesInApplication
                    ? 'Mulitple role in application'
                    : role.deleted
                      ? 'Click to add role'
                      : ''
                }
                name={`application[${index}]`}
                error={role.deleted}
                value={role.applicationRole?.roleName?.replace(
                  'AZU-DEL-APP_',
                  '',
                )}
              />
            ))}
          {availableRoles.map((role: any, index: any) => (
            <MDTextField
              key={index}
              className={`transparent`}
              control={control}
              setValue={setValue}
              label={'Click to add role'}
              name={role}
              error={false}
              value={role}
              onClick={() => handleConfirmAddRole(availableRoles[index])}
            />
          ))}
        </div>

        <div>
          <div
            style={{
              display:
                data?.tpaRoles?.length !== undefined &&
                data?.tpaRoles?.length > 0
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Roles - Extra access',
                  'These are the roles that have a relation to the user, but as extra access granted by other companies.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Roles - Extra access
            </h3>
          </div>
          {data?.tpaRoles
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((role, index) => (
              <MDTextField
                key={index}
                className={`inputField ${
                  role.applicationRole?.application?.applicationNumber ===
                  multipleRolesInApplication
                    ? 'flash'
                    : ''
                } ${data?.deleted ? 'transparent' : ''}`}
                control={control}
                setValue={setValue}
                label={''}
                name={`application[${index}]`}
                error={role.deleted}
                value={role.applicationRole?.roleName?.replace(
                  'AZU-DEL-APP_',
                  '',
                )}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              display:
                data?.btps?.length !== undefined && data?.btps?.length > 0
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Business Trading Partner',
                  'These are the Business Trading Partners that have a relation to the user. \n Only one Business Trading Partner should have an active relation.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Business Trading Partner
            </h3>
          </div>
          {data?.btps
            ?.sort((a, b) => {
              // Sort by deleted: false first
              if (a.deleted && !b.deleted) {
                return 1;
              }
              if (!a.deleted && b.deleted) {
                return -1;
              }

              // If both have the same deleted status, sort by number alphabetically
              const btpNumberA = a.btp?.btpNumber || '';
              const btpNumberB = b.btp?.btpNumber || '';

              if (btpNumberA < btpNumberB) {
                return -1;
              }
              if (btpNumberA > btpNumberB) {
                return 1;
              }
              return 0;
            })
            .map((btp, index) => (
              <MDTextField
                key={index}
                className={`inputField ${
                  btp.deleted === false && (differentBtp || userTooManyBtps)
                    ? 'flash'
                    : ''
                } ${btp.deleted ? 'transparent' : ''}`}
                control={control}
                setValue={setValue}
                label={
                  btp.deleted === false && (differentBtp || userTooManyBtps)
                    ? `${btp.btp?.btpName} - Check Btp`
                    : `${btp.btp?.btpName}`
                }
                name={`btp[${index}]`}
                error={btp.deleted}
                value={btp.btp?.btpNumber}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              display:
                data?.tpaBtps?.length !== undefined && data?.tpaBtps?.length > 0
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Business Trading Partner - Extra access',
                  'These are the Business Trading Partners that have a relation to the user, but as extra access granted by other companies.\n Only one Business Trading Partner should have an active relation.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Business Trading Partner - Extra access
            </h3>
          </div>
          {data?.tpaBtps
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((btp, index) => (
              <MDTextField
                key={index}
                className={`inputField ${btp.deleted ? 'transparent' : ''}`}
                control={control}
                setValue={setValue}
                label={`${btp.btp?.btpName}`}
                name={`btp[${index}]`}
                error={btp.deleted}
                value={btp.btp?.btpNumber}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              display:
                btpPersonData?.length !== undefined && btpPersonData?.length > 0
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Business Trading Partner',
                  'These are the Business Trading Partners that have a relation to the contact, which has relation to BestOne. \nOnly one Business Trading Partner should be active.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Contacts Business Trading Partner
            </h3>
          </div>
          {btpPersonData
            ?.sort((a, b) => {
              // Sort by deleted: false first
              if (a.deleted && !b.deleted) {
                return 1;
              }
              if (!a.deleted && b.deleted) {
                return -1;
              }

              // If both have the same deleted status, sort by shipToNumber alphabetically
              const btpNumberA = a.btp?.btpNumber || '';
              const btpNumberB = b.btp?.btpNumber || '';

              if (btpNumberA < btpNumberB) {
                return -1;
              }
              if (btpNumberA > btpNumberB) {
                return 1;
              }
              return 0;
            })
            .map((btp, index) => (
              <MDTextField
                key={index}
                className={`inputField ${
                  !btp.deleted && (differentBtp || personTooManyBtps)
                    ? 'flash'
                    : ''
                } ${btp.deleted ? 'transparent' : ''}`}
                control={control}
                setValue={setValue}
                label={
                  !btp.deleted && (differentBtp || personTooManyBtps)
                    ? `${btp.btp?.btpName} - Check Btp`
                    : `${btp.btp?.btpName}`
                }
                name={`btp[${index}]`}
                error={btp.deleted}
                value={btp.btp?.btpNumber}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              justifyContent: 'center',
              display:
                data?.invoiceAccounts?.length !== undefined &&
                data?.invoiceAccounts?.length > 0
                  ? 'flex'
                  : 'none',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Invoice Accounts',
                  'These are the Invoice Accounts that have a relation to the user.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Invoice Account
            </h3>
          </div>
          {data?.invoiceAccounts
            ?.sort((a, b) => {
              // Sort by deleted: false first
              if (a.deleted && !b.deleted) {
                return 1;
              }
              if (!a.deleted && b.deleted) {
                return -1;
              }

              // If both have the same deleted status, sort by invoiceAccountNumber alphabetically
              const invoiceAccountNumberA =
                a.invoiceAccount?.invoiceAccountNumber || '';
              const invoiceAccountNumberB =
                b.invoiceAccount?.invoiceAccountNumber || '';

              if (invoiceAccountNumberA < invoiceAccountNumberB) {
                return -1;
              }
              if (invoiceAccountNumberA > invoiceAccountNumberB) {
                return 1;
              }
              return 0;
            })
            .map((invoiceAccount, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                label={invoiceAccount.invoiceAccount?.invoiceAccountName ?? ''}
                name={`invoiceAccount[${index}]`}
                error={invoiceAccount.deleted}
                value={invoiceAccount.invoiceAccount?.invoiceAccountNumber}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              justifyContent: 'center',
              display:
                data?.tpaInvoiceAccounts?.length !== undefined &&
                data?.tpaInvoiceAccounts?.length > 0
                  ? 'flex'
                  : 'none',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Invoice Accounts - Extra Access',
                  'These are the Invoice Accounts that have a relation to the user, but as extra access granted by other companies.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Invoice Account - Extra Access
            </h3>
          </div>
          {data?.tpaInvoiceAccounts
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((invoiceAccount, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                label={invoiceAccount.invoiceAccount?.invoiceAccountName ?? ''}
                name={`invoiceAccount[${index}]`}
                error={invoiceAccount.deleted}
                value={invoiceAccount.invoiceAccount?.invoiceAccountNumber}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              justifyContent: 'center',
              display:
                data?.shipTos?.length !== undefined && data?.shipTos?.length > 0
                  ? 'flex'
                  : 'none',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Ship Tos',
                  'These are the Ship Tos that have a relation to the user. \n If this is an admin of Customer Portal the list of Ship Tos will be all Ship Tos within the company (Business Trading Partner)\nIf this user is not an admin then the Ship To(s) should be manually added by the company admin or you can do it by either click on an active or inactive relation to a Ship To to change the relation.\nThe red mark means that the user no longer has an active relation.',
                )
              }
              onMouseLeave={handleMouseLeave}
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }} // Flexbox for alignment
            >
              Ship Tos
            </h3>
          </div>
          {data?.shipTos
            ?.sort((a, b) => {
              // Sort by deleted: false first
              if (a.deleted && !b.deleted) {
                return 1;
              }
              if (!a.deleted && b.deleted) {
                return -1;
              }

              // If both have the same deleted status, sort by shipToNumber alphabetically
              const shipToNumberA = a.shipTo?.shipToNumber || '';
              const shipToNumberB = b.shipTo?.shipToNumber || '';

              if (shipToNumberA < shipToNumberB) {
                return -1;
              }
              if (shipToNumberA > shipToNumberB) {
                return 1;
              }
              return 0;
            })
            .map((shipTo, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                onClick={() =>
                  data?.roles !== null &&
                  data?.roles?.some(
                    (x) =>
                      (x.applicationRole?.roleName ===
                        'Customer Portal Admin' ||
                        x.applicationRole?.roleName === 'MORE Admin') &&
                      x.deleted === false,
                  )
                    ? null
                    : shipTo.deleted
                      ? handleConfirmAddShipTo(shipTo.shipTo?.shipToNumber)
                      : handleConfirmRemoveShipTo(shipTo.shipTo?.shipToNumber)
                }
                // label={shipTo.shipTo?.shipToName ?? ''}
                label={
                  shipTo.deleted === false && shipTo.shipTo?.shipToName
                    ? shipTo.shipTo?.shipToName
                    : 'Click to add Ship To'
                }
                name={`shipTo[${index}]`}
                error={shipTo.deleted}
                value={shipTo.shipTo?.shipToNumber}
              />
            ))}
          {availableShipTos.map((role: any, index: any) => (
            <MDTextField
              key={index}
              className={`transparent`}
              control={control}
              setValue={setValue}
              label={'Click to add Ship To'}
              name={role}
              error={false}
              value={role}
              onClick={() => handleConfirmAddShipTo(availableShipTos[index])}
            />
          ))}
        </div>

        <div>
          <div
            style={{
              justifyContent: 'center',
              display:
                data?.tpaShipTos?.length !== undefined &&
                data?.tpaShipTos?.length > 0
                  ? 'flex'
                  : 'none',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Ship To - Extra Access',
                  'These are the Ship Tos that have a relation to the user, but as extra access granted by other companies. \n If this is an admin of Customer Portal the list of Ship Tos will be all Ship Tos within the company (Business Trading Partner), if not then the Ship To(s) should be manually added by the company admin.\nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Ship Tos - Extra Access
            </h3>
          </div>
          {data?.tpaShipTos
            ?.sort((a, b) => (a.deleted ? 1 : b.deleted ? -1 : 0)) // Sort by deleted: false first
            .map((shipTo, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                label={shipTo.shipTo?.shipToName ?? ''}
                name={`shipTo[${index}]`}
                error={shipTo.deleted}
                value={shipTo.shipTo?.shipToNumber}
              />
            ))}
        </div>

        <div>
          <div
            style={{
              display:
                data?.suppliers?.length !== undefined &&
                data?.suppliers?.length > 0
                  ? 'flex'
                  : 'none',
              justifyContent: 'center',
            }}
          >
            <h3
              onMouseEnter={(event) =>
                handleMouseEnter(
                  event,
                  'Suppliers',
                  'These are the Supplier that have a relation to the user. \nThe red mark means that the user no longer has an active relation',
                )
              }
              onMouseLeave={handleMouseLeave}
            >
              Suppliers
            </h3>
          </div>
          {data?.suppliers
            ?.sort((a, b) => {
              // Sort by deleted: false first
              if (a.deleted && !b.deleted) {
                return 1;
              }
              if (!a.deleted && b.deleted) {
                return -1;
              }

              // If both have the same deleted status, sort by shipToNumber alphabetically
              const supplierNumberA = a.supplier?.supplierNumber || '';
              const supplierNumberB = b.supplier?.supplierNumber || '';

              if (supplierNumberA < supplierNumberB) {
                return -1;
              }
              if (supplierNumberA > supplierNumberB) {
                return 1;
              }
              return 0;
            })
            .map((supplier, index) => (
              <MDTextField
                key={index}
                className={
                  data?.deleted ? 'inputField transparent' : 'inputField'
                }
                control={control}
                setValue={setValue}
                label={supplier.supplier?.supplierName ?? ''}
                name={`supplier[${index}]`}
                error={supplier.deleted}
                value={supplier.supplier?.supplierNumber}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default UserViewComponent;
